import PersonAddIcon from '@material-ui/icons/PersonAdd'
import ActionButton from '../ActionButton/ActionButton'
import { withRouter } from 'react-router-dom'
import { CREATE_PROFILE } from '../../globalConstants'
import { getCompanyId } from '../../windowManager'

export const CreateProfileButton = ({ history }) => {
  return (
    <ActionButton
      text={CREATE_PROFILE}
      variant={'contained'}
      icon={<PersonAddIcon />}
      color={'primary'}
      onClick={() =>
        history.push(`/create-profile#?company_id=${getCompanyId()}`)
      }
    />
  )
}

export default withRouter(CreateProfileButton)
