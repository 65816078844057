import {
  SET_PROFILE_NAME,
  SET_PROFILE_PHONE,
  SET_PROFILE_SAVED,
} from './actionType'
import {
  MIN_NAME_LENGTH,
  MIN_PHONENUMBER_LENGTH,
  MAX_PHONENUMBER_LENGTH,
} from '../../globalConstants'

export const initialState = {
  profileName: '',
  profilePhone: '',
  minNameLengthReached: false,
  maxPhoneNumberLengthReached: false,
  saved: false,
}

export default function createProfileReducer(
  state = initialState,
  action = {},
) {
  switch (action.type) {
    case SET_PROFILE_NAME: {
      const newState = {
        ...state,
        profileName: action.profileName,
        minNameLengthReached: action.profileName.length >= MIN_NAME_LENGTH,
        saved: false,
      }
      return newState
    }
    case SET_PROFILE_PHONE: {
      const newState = {
        ...state,
        profilePhone: action.profilePhone,
        maxPhoneNumberLengthReached:
          action.profilePhone.length >= MAX_PHONENUMBER_LENGTH ||
          action.profilePhone.length === MIN_PHONENUMBER_LENGTH,
        saved: false,
      }
      return newState
    }
    case SET_PROFILE_SAVED: {
      const newState = {
        ...state,
        saved: action.saved,
      }
      return newState
    }
    default:
      return state
  }
}
