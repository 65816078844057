import WorkIcon from '@material-ui/icons/Work'
import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { WORKORDER } from '../../globalConstants'
import ActionButton from '../ActionButton/ActionButton'

export const WorkOrderDetailButton = ({ workOrder, session }) => {
  return (
    <Link
      to={`/work-order-detail#?work_order_number=${workOrder.workOrderNumber}&company_id=${workOrder.companyId}&technician_id=${session.userInfo.technicianId}`}
    >
      <ActionButton text={WORKORDER} icon={<WorkIcon />} />
    </Link>
  )
}

const mapStateToProps = (state) => {
  return {
    session: state.authCredentialsReducer.auth.session,
  }
}

export default withRouter(connect(mapStateToProps, null)(WorkOrderDetailButton))
