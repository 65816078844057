import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import { connect } from 'react-redux'
import { closeSideNav } from '../../store/layout/actionCreator'
import ListSubheader from '@material-ui/core/ListSubheader'
import { Divider } from '@material-ui/core'
import { Link } from 'react-router-dom'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import HomeIcon from '@material-ui/icons/Home'
import { withStyles } from '@material-ui/core/styles'

const styles = {
  link: {
    color: 'inherit',
    textDecoration: 'none',
  },
}

export const Sidemenu = ({ sideNavIsOpen, closeSideNav, classes }) => {
  return (
    <Drawer open={sideNavIsOpen} onClose={closeSideNav}>
      <List>
        <Divider />
        <ListSubheader>Last Name, First Name</ListSubheader>
        <Divider />
        <Link className={classes.link} to={'/home'} onClick={closeSideNav}>
          <ListItem button>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary={'Home'} />
          </ListItem>
        </Link>
      </List>
    </Drawer>
  )
}

const mapStateToProps = (state) => {
  return {
    sideNavIsOpen: state.layout.sideNavIsOpen,
  }
}

const mapDispatchToProps = {
  closeSideNav,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(Sidemenu))
