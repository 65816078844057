// These dependencies are bundled within praxis-scripts
import { BrowserRouter as Router } from 'react-router-dom'
import Layout from './Layout/Layout'
import { EnvProvider } from '@praxis/component-runtime-env'
import AppRestrictedDialog from '../common/AppRestrictedDialog/AppRestrictedDialog'

const App = () => {
  // const href = window.location.href
  // href.includes('#?') && window.sessionStorage.setItem('href', href)
  return (
    <EnvProvider
      commonConfig={{ praxis: { docsText: 'Explore the documentation' } }}
      configPath={
        process.env.NODE_ENV === 'development'
          ? '/config.json'
          : '/app-environment'
      }
    >
      <Router>
        <AppRestrictedDialog />
        <Layout />
      </Router>
    </EnvProvider>
  )
}

export default App
