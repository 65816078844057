import { Typography } from '@material-ui/core'

import { connect } from 'react-redux'

const CompanyName = ({ companyName }) => {
  return <Typography noWrap>{companyName}</Typography>
}

const mapStateToProps = (state) => {
  return {
    companyName: state.authCredentialsReducer.companyName,
  }
}

export default connect(mapStateToProps, null)(CompanyName)
