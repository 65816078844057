import { connect } from 'react-redux'
import DatePicker from '../../DatePicker/DatePicker'
import AppHeader from '../../Header/Header'
import VisitComments from '../VisitComments/VisitComments'
import Grid from '@material-ui/core/Grid'
import { getURLSearchParams, getCompanyId } from '../../../windowManager'
import { withStyles } from '@material-ui/core/styles'
import moment from 'moment'
import { toggleRender } from '../../MatLinearProgress/actionCreator'
import { setMessage } from '../../UserFeedback/actionCreator'
import { withRouter } from 'react-router-dom'
import { withApollo } from 'react-apollo'
import { gql } from 'apollo-boost'
import DepartureBoard from '@material-ui/icons/DepartureBoard'
import { UTC_FORMAT, NO_CACHE, EN_ROUTE } from '../../../globalConstants'
import ActionButton from '../../ActionButton/ActionButton'

export const UPDATE_STATUS_ETA = gql`
  mutation UpdateVisit($visit: VisitInput!, $visitId: String!) {
    updateVisit(visit: $visit, visitId: $visitId) {
      sourceId
    }
  }
`
const styles = (theme) => ({
  gridParent: {
    backgroundColor: theme.palette.secondary.action,
  },
  gridChild: {
    margin: theme.spacing(2),
  },
  gridLast: {
    marginTop: theme.spacing(0),
  },
})

const UpdateETA = ({
  classes,
  selectedDateTime,
  visitComments,
  setMessage,
  toggleRender,
  history,
  client,
  session,
}) => {
  let estimatedTimeOfArrival = selectedDateTime || new Date()
  estimatedTimeOfArrival = moment(estimatedTimeOfArrival)
    .utc()
    .format(UTC_FORMAT)

  const searchParams = getURLSearchParams()
  const visitId = searchParams.get('visit_id')
  const wonum = searchParams.get('wonum')
  const userInfo = session.userInfo
  const technicianName = userInfo.name
  const technicianPhone = userInfo.phone

  const updateVisit = async () => {
    let response = {
      data: {
        updateVisit: {
          sourceId: '',
        },
      },
    }

    try {
      toggleRender(true)
      setMessage(`Attempting to update ETA for Visit #${visitId}`)
      const body = {
        visit_status: EN_ROUTE,
        visit_comments: visitComments,
        technician_name: technicianName,
        visitor_phone: technicianPhone,
        estimated_time_arrival: estimatedTimeOfArrival,
      }
      response = await client.mutate({
        mutation: UPDATE_STATUS_ETA,
        variables: {
          visit: body,
          visitId: visitId,
        },
        fetchPolicy: NO_CACHE,
      })
    } catch (e) {
      setMessage(`Unable to update ETA for Visit #${visitId}`)
    } finally {
      toggleRender(false)
      response.data.updateVisit.sourceId &&
        history.push(`/search#?company_id=${getCompanyId()}&wonum=${wonum}`)
    }
  }

  return (
    <>
      <AppHeader arrowBack title={'Update ETA'} />
      <Grid container className={classes.gridParent}>
        <Grid item xs={12} className={classes.gridChild}>
          <DatePicker />
        </Grid>
        <Grid item xs={12} className={classes.gridChild}>
          <VisitComments visitId={visitId} errorMessageShown={false} />
        </Grid>
        <Grid
          item
          xs={12}
          align={'right'}
          className={`${classes.gridChild} ${classes.gridLast}`}
        >
          <ActionButton
            text={'confirm eta'}
            icon={<DepartureBoard />}
            onClick={updateVisit}
          />
        </Grid>
      </Grid>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    selectedDateTime: state.selectedDateReducer.selectedDateTime,
    session: state.authCredentialsReducer.auth.session,
    visitComments: state.visitCommentReducer.visitComments,
  }
}

const mapDispatchToProps = {
  setMessage,
  toggleRender,
}
export default withApollo(
  withStyles(styles)(
    connect(mapStateToProps, mapDispatchToProps)(withRouter(UpdateETA)),
  ),
)
