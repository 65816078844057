import { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import AppHeader from '../Header/Header'
import Grid from '@material-ui/core/Grid'
import { Typography } from '@material-ui/core'
import NameInput from './NameInput'
import PhoneNumberInput from './PhoneNumberInput'
import SaveProfileButton from './SaveProfileButton'
import {
  setProfileName,
  setProfilePhone,
  setProfileSaved,
} from './actionCreator'
import { CREATE_PROFILE } from '../../globalConstants'
import { getLocalStorage } from '../../windowManager'
import LocalStorageDisabledMessage from '../LocalStorageDisabledMessage/LocalStorageDisabledMessage'

const styles = (theme) => ({
  gridParent: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.secondary.action,
  },
  gridItem: {
    paddingTop: theme.spacing(2),
  },
  // larger layout only stuff
  firstInput: {
    [theme.breakpoints.up('sm')]: {
      paddingRight: theme.spacing(4),
    },
  },
})

export class CreateProfile extends Component {
  constructor() {
    super()
    this.state = {
      canUseLocalStorage: true,
    }
  }

  componentDidMount = () => {
    const { setProfileName, setProfilePhone, setProfileSaved } = this.props
    setProfileName('')
    setProfilePhone('')
    setProfileSaved(false)
    this.setState({ canUseLocalStorage: getLocalStorage() })
  }

  getCreateProfileForm = () => {
    const { classes } = this.props
    return (
      <Grid container className={classes.gridParent}>
        <Grid item xs={12}>
          <Typography variant={'h6'}>{CREATE_PROFILE}:</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          className={`${classes.gridItem} ${classes.firstInput}`}
        >
          <NameInput createProfile />
        </Grid>
        <Grid item xs={12} sm={6} className={classes.gridItem}>
          <PhoneNumberInput createProfile />
        </Grid>
        <Grid
          item
          container
          xs={12}
          justifyContent={'flex-end'}
          className={classes.gridItem}
        >
          <SaveProfileButton />
        </Grid>
      </Grid>
    )
  }

  render = () => {
    return (
      <>
        <AppHeader arrowBack title={CREATE_PROFILE} />
        {this.state.canUseLocalStorage ? (
          this.getCreateProfileForm()
        ) : (
          <LocalStorageDisabledMessage />
        )}
      </>
    )
  }
}

const mapDispatchToProps = {
  setProfileName,
  setProfilePhone,
  setProfileSaved,
}

export default withStyles(styles)(
  connect(null, mapDispatchToProps)(CreateProfile),
)
