import { Component } from 'react'
import { Typography } from '@material-ui/core'
import { getBrowser, getOS } from '../../../windowManager'
import ActionButton from '../../ActionButton/ActionButton'
import HelpIcon from '@material-ui/icons/Help'
import { CHECK_IN, CHECK_OUT } from '../../../globalConstants'

const onClick = (url) => {
  window.open(url, '_blank')
}

const BROWSER = 'Browser'
const OS = 'Operating System'
const GPS_OR_LOCATION = 'GPS or Location Service'

class GpsHelp extends Component {
  constructor() {
    super()
    this.state = {
      os: getOS(),
      browser: getBrowser(),
    }
  }
  render = () => {
    const { os, browser } = this.state

    return (
      <>
        <Typography variant="h6" gutterBottom paragraph>
          How can I enable {GPS_OR_LOCATION} on my device?
        </Typography>
        <Typography gutterBottom paragraph>
          {GPS_OR_LOCATION} settings can be found in both your {OS} and your{' '}
          {BROWSER}.
        </Typography>
        <Typography gutterBottom paragraph>
          Your ability to {CHECK_IN} or {CHECK_OUT} can be affected by both.
        </Typography>
        <Typography gutterBottom paragraph>
          We have detected your {OS} as {os.name} and your {BROWSER} as{' '}
          {browser.name}.
        </Typography>
        {os.url && browser.url && (
          <Typography gutterBottom paragraph>
            Please visit the link(s) below for {GPS_OR_LOCATION} help with your
            specific {BROWSER}, {OS}, or both.
          </Typography>
        )}
        {os.url && (
          <ActionButton
            onClick={() => onClick(os.url)}
            icon={<HelpIcon />}
            text={`help for ${os.name}`}
          />
        )}
        {browser.url && (
          <ActionButton
            onClick={() => onClick(browser.url)}
            icon={<HelpIcon />}
            text={`help for ${browser.name}`}
          />
        )}
      </>
    )
  }
}

export default GpsHelp
