import { Component } from 'react'
import AppHeader from '../../Header/Header'
import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import PhotoPageButton from '../../VisitDetail/PhotoPageButton'
import Link from '@material-ui/core/Link'
import CallIcon from '@material-ui/icons/Call'
import { Typography } from '@material-ui/core'
import { getURLSearchParams, getCompanyId } from '../../../windowManager'
import { gql } from 'apollo-boost'
import { withApollo } from 'react-apollo'
import { toggleRender } from '../../MatLinearProgress/actionCreator'
import { setMessage } from '../../UserFeedback/actionCreator'
import { connect } from 'react-redux'
import {
  DATE_MISSING,
  LOCAL_FORMAT,
  NO_CACHE,
  ARRIVE_BY,
  REPAIR_BY,
  UPLOAD_PHOTOS,
} from '../../../globalConstants'
import moment from 'moment'
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto'

const styles = (theme) => ({
  gridParent: {
    padding: theme.spacing(1.5, 0, 1.5, 0),
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.secondary.action,
  },
  gridChild: {
    padding: theme.spacing(2),
  },
  gridButtons: {
    margin: theme.spacing(1),
  },
  callIcon: {
    marginRight: theme.spacing(1),
  },
  button: {
    padding: theme.spacing(2),
    color: theme.palette.primary.action,
  },
  gridMissing: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.primary.alternate,
  },
})

const STORE_PHONE_NUMBER = 'Store Phone Number'
const MULTI_LINE_ROWS = 6

export const GET_WORK_ORDER = gql`
  query GetWorkOrder(
    $woNum: String!
    $companyId: String!
    $technicianId: String!
  ) {
    getWorkOrder(
      woNum: $woNum
      companyId: $companyId
      technicianId: $technicianId
    ) {
      workOrderNumber
      locationId
      problemCode
      assetName
      vndrIntmDate
      status
      workOrderDescription
      detailDescription
      pmContract {
        responseHours
      }
      location {
        addresses {
          city
          region
          postalCode
          address
        }
        locations {
          locationName
        }
      }
      expectedResponseTime
      expectedRepairTime
    }
  }
`

export class WorkOrderDetail extends Component {
  constructor() {
    super()
    this.state = {
      workOrder: {
        workOrderNumber: null,
        location: {
          locations: [
            {
              locationName: 'test',
            },
          ],
        },
      },
    }
  }
  componentDidMount = async () => {
    const searchParams = getURLSearchParams()
    const workOrderNumber = searchParams.get('work_order_number')
    const technicianId = searchParams.get('technician_id')
    const { client, setMessage, toggleRender } = this.props
    let message = null

    try {
      toggleRender(true)
      setMessage(
        `Attempting to load details for Work Order #${workOrderNumber}...`,
      )
      const result = await client.query({
        query: GET_WORK_ORDER,
        variables: {
          woNum: workOrderNumber,
          companyId: getCompanyId(),
          technicianId: technicianId,
        },
        fetchPolicy: NO_CACHE,
      })
      this.setState({ workOrder: result.data.getWorkOrder[0] })
    } catch (e) {
      message = e.message
    } finally {
      setMessage(message)
      toggleRender(false)
    }
  }

  render = () => {
    const { classes } = this.props
    const workOrder = this.state.workOrder
    const expectedResponseTime = workOrder.expectedResponseTime
    const workOrderDescription = workOrder.workOrderDescription
    const detailDescription = workOrder.detailDescription
    const telephoneNumber = workOrder.telephoneNumber
    const vndrIntmDate = workOrder.vndrIntmDate
    const expectedRepairTime = workOrder.expectedRepairTime
    const toLocalTime = (time) =>
      time ? moment(time).local().format(LOCAL_FORMAT) : DATE_MISSING

    const gridItems = [
      {
        label: 'Work Order Number',
        value: workOrder.workOrderNumber,
      },
      {
        label: 'Long Description',
        value: detailDescription,
        multiline: true,
        rows: MULTI_LINE_ROWS,
        disabled: !detailDescription,
      },
      {
        label: 'Sent to Vendor',
        value: toLocalTime(vndrIntmDate),
      },
      {
        label: ARRIVE_BY,
        value: toLocalTime(expectedResponseTime),
      },
      {
        label: REPAIR_BY,
        value: toLocalTime(expectedRepairTime),
      },
      {
        label: 'Site ID',
        value: workOrder.locationId,
      },
      {
        label: 'Store Location',
        value: workOrder.location.locations[0].locationName,
      },
      {
        label: STORE_PHONE_NUMBER,
        value: telephoneNumber,
        disabled: true,
      },
      {
        label: 'Work Order Status',
        value: workOrder.status,
      },
      {
        label: 'Asset',
        value: workOrder.assetName,
      },
      {
        label: 'Short Description',
        value: workOrderDescription,
        multiline: true,
        rows: MULTI_LINE_ROWS,
        disabled: !workOrderDescription,
      },
      {
        label: 'Problem Code',
        value: workOrder.problemCode,
      },
    ]
    return (
      <>
        <AppHeader arrowBack title={'Work Order Detail'} />
        {workOrder.workOrderNumber && (
          <>
            <Grid container item className={classes.gridParent}>
              {gridItems.map((gridItem, key) => (
                <Grid
                  item
                  className={classes.gridChild}
                  xs={12}
                  sm={6}
                  key={key}
                >
                  {
                    // if the grid item does not contain the store number, it's a standard text field
                    gridItem.label !== STORE_PHONE_NUMBER ? (
                      <TextField
                        fullWidth
                        label={gridItem.label}
                        value={gridItem.value}
                        multiline={gridItem.multiline}
                        minRows={gridItem.rows}
                        variant={'outlined'}
                        disabled={gridItem.disabled}
                      />
                    ) : // if it is the phone number data point, does it actually contain a phone number?
                    telephoneNumber ? (
                      <Link href={`tel:${gridItem.value}`}>
                        <Button
                          fullWidth
                          className={classes.button}
                          variant={'outlined'}
                        >
                          <CallIcon className={classes.callIcon} />
                          <Typography variant={'button'}>
                            {gridItem.value}
                          </Typography>
                        </Button>
                      </Link>
                    ) : (
                      // if it is the phone number data point, but doesn't actually contain a phone number...
                      <Typography className={classes.gridMissing}>
                        ( phone number missing )
                      </Typography>
                    )
                  }
                </Grid>
              ))}
            </Grid>
            <Grid container>
              <Grid item xs={12} className={classes.gridButtons}>
                <PhotoPageButton
                  text={UPLOAD_PHOTOS}
                  icon={<AddAPhotoIcon />}
                  baseUrl="/image-upload"
                />
              </Grid>
              <Grid item xs={12} className={classes.gridButtons}>
                <PhotoPageButton />
              </Grid>
            </Grid>
          </>
        )}
      </>
    )
  }
}
const mapDispatchToProps = {
  setMessage,
  toggleRender,
}

export default withStyles(styles)(
  withApollo(connect(null, mapDispatchToProps)(WorkOrderDetail)),
)
