import { SET_CURRENT_POSITION, SET_MESSAGE } from './actionType'

const initialState = {
  currentPosition: {
    latitude: null,
    longitude: null,
  },
  message: 'idle',
}

export default function geoPositionReducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_CURRENT_POSITION: {
      const newState = {
        ...state,
        currentPosition: {
          latitude: action.currentPosition.latitude,
          longitude: action.currentPosition.longitude,
        },
      }
      return newState
    }
    case SET_MESSAGE: {
      const newState = {
        ...state,
        message: action.message,
      }
      return newState
    }
    default:
      return state
  }
}
