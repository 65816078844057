import { withStyles } from '@material-ui/core/styles'
import moment from 'moment'
import { Typography } from '@material-ui/core'
import { LOCAL_FORMAT } from '../../globalConstants'
const styles = (theme) => ({
  visitTimeExpectation: {
    color: theme.palette.secondary.main,
    marginTop: theme.spacing(2),
    textAlign: 'center',
  },
})
const UtcTimeToLocal = ({
  classes,
  date,
  text = 'Your visit will expire at',
}) => {
  const content =
    date === 'null' ? 'N/A' : moment(date).local().format(LOCAL_FORMAT)
  return (
    <Typography className={classes.visitTimeExpectation}>
      {text} {content}
    </Typography>
  )
}
export default withStyles(styles)(UtcTimeToLocal)
