import { CLOSE_SIDE_NAV, OPEN_SIDE_NAV } from './actionType'

export const initialState = {
  sideNavIsOpen: false,
}

export default function layoutReducer(state = initialState, action = {}) {
  switch (action.type) {
    case OPEN_SIDE_NAV: {
      const newState = {
        sideNavIsOpen: true,
      }
      return newState
    }
    case CLOSE_SIDE_NAV: {
      const newState = {
        sideNavIsOpen: false,
      }
      return newState
    }
    default:
      return state
  }
}
