import { Component } from 'react'
import IconButton from '@material-ui/core/IconButton'
import SaveIcon from '@material-ui/icons/Save'
import { Tooltip } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { saveNotesQuery } from '../WorkOrder/VisitComments/SaveNotesQuery'
import { setMessage } from '../GeoLocation/actionCreator'
import { toggleRender } from '../MatLinearProgress/actionCreator'
import { connect } from 'react-redux'
import { withApollo } from 'react-apollo'
import { withRouter } from 'react-router-dom'
import { NO_CACHE } from '../../globalConstants'

const styles = (theme) => ({
  actionButton: {
    margin: theme.spacing(0, -1.5, 0, 0.5),
    color: theme.palette.secondary.action,
  },
})

const SAVE_NOTES = 'save notes'

class SaveButton extends Component {
  render = () => {
    const {
      classes,
      visitId,
      client,
      setMessage,
      toggleRender,
      visitComments,
    } = this.props

    const saveNotes = async () => {
      try {
        setMessage(`Attempting to ${SAVE_NOTES} for Visit #${visitId}`)
        toggleRender(true)
        await client.mutate({
          mutation: saveNotesQuery,
          variables: {
            visit: { visit_comments: visitComments.trim() },
            visitId: visitId,
          },
          fetchPolicy: NO_CACHE,
        })
        setMessage(`Successfully saved notes for Visit #${visitId}`)
      } catch (e) {
        setMessage(`Unable to ${SAVE_NOTES} for Visit #${visitId}`)
      } finally {
        toggleRender(false)
      }
    }

    const onClickSaveNotesButton = () => {
      saveNotes()
    }

    return (
      <Tooltip title={SAVE_NOTES} aria-label={SAVE_NOTES}>
        <IconButton
          aria-label={SAVE_NOTES}
          className={classes.actionButton}
          onClick={onClickSaveNotesButton}
        >
          <SaveIcon />
        </IconButton>
      </Tooltip>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    visitComments: state.visitCommentReducer.visitComments,
  }
}

const mapDispatchToProps = {
  setMessage,
  toggleRender,
}

export default withApollo(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withStyles(styles)(withRouter(SaveButton))),
)
