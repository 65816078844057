import { combineReducers } from 'redux'
import layout from '../store/layout/reducer'
import geoLocationReducer from '../components/GeoLocation/reducer'
import myWorkOrdersReducer from '../components/MyWork/reducer'
import searchedWorkOrdersReducer from '../components/Search/reducer'
import userFeedbackReducer from '../components/UserFeedback/reducer'
import linearProgressReducer from '../components/MatLinearProgress/reducer'
import selectedDateReducer from '../components/DatePicker/reducer'
import visitCommentReducer from '../components/WorkOrder/VisitComments/reducer'
import authCredentialsReducer from '../common/UserAuth/reducer'
import followUpReasonReducer from '../components/VisitDetail/FollowUpReasonDropdown/reducer'
import causeReducer from '../components/VisitDetail/CauseDropdown/reducer'
import remedyReducer from '../components/VisitDetail/RemedyDropdown/reducer'
import splashScreenReducer from '../components/SplashScreen/reducer'
import createProfileReducer from '../components/CreateProfile/reducer'
import editProfileReducer from '../components/EditProfile/reducer'
import fixedButtonReducer from '../components/VisitDetail/FixedButton/reducer'

// ✅ SEARCHFILTER IMPORT MUST MATCH THE FOLDER NAME. SAME AS ALL THE OTHERS
const rootReducer = combineReducers({
  layout,
  geoLocationReducer,
  myWorkOrdersReducer,
  searchedWorkOrdersReducer,
  userFeedbackReducer,
  linearProgressReducer,
  selectedDateReducer,
  visitCommentReducer,
  authCredentialsReducer,
  followUpReasonReducer,
  causeReducer,
  remedyReducer,
  splashScreenReducer,
  createProfileReducer,
  editProfileReducer,
  fixedButtonReducer,
})

export default rootReducer
