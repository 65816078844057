import { SET_REMEDY } from './actionType'

export const initialState = {
  remedy: '',
}

export default function remedyReducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_REMEDY: {
      const newState = {
        ...state,
        remedy: action.remedy,
      }
      return newState
    }
    default:
      return state
  }
}
