import Assignment from '@material-ui/icons/Assignment'
import { withRouter } from 'react-router-dom'
import { CHECK_IN, NEXT_STEPS } from '../../globalConstants'
import ActionButton from '../ActionButton/ActionButton'
import { connect } from 'react-redux'

export const VisitDetailButton = ({ workOrder, history, session }) => {
  const workOrderNumber = workOrder.workOrderNumber
  const workOrderVisits = workOrder.visits
  const firstWorkOrderVisit = workOrderVisits[0]
  const workOrderVisitsEmpty =
    workOrderVisits !== null && !workOrderVisits.length
  const visitExpiration = firstWorkOrderVisit.visitExpiration
  const expectedRepairTime = workOrder.expectedRepairTime

  const onClickToVisitDetails = () => {
    if (!workOrderVisitsEmpty) {
      const url = `/visit-detail#?visit_id=${
        firstWorkOrderVisit.visitId
      }&work_order_number=${workOrderNumber}&status=${
        workOrder.status
      }&company_id=${workOrder.companyId}&location=${
        workOrder.location.locations[0].locationName
      }&problem_code=${
        workOrder.problemCode
      }&visit_expiration=${window.encodeURIComponent(visitExpiration)}
      &expected_repair_time=${window.encodeURIComponent(expectedRepairTime)}`
      history.push(url)
    }
  }

  return (
    firstWorkOrderVisit.visitStatus === CHECK_IN && (
      <ActionButton
        text={`${NEXT_STEPS} as ${session.userInfo.name}`}
        fullWidth
        icon={<Assignment />}
        onClick={onClickToVisitDetails}
      />
    )
  )
}

const mapStateToProps = (state) => {
  return {
    session: state.authCredentialsReducer.auth.session,
  }
}

export default connect(mapStateToProps, null)(withRouter(VisitDetailButton))
