import {
  SET_SEARCHED_WORK_ORDERS,
  SET_SEARCH_OPTION,
  SET_SEARCHED_STOREID_WORK_ORDERS,
} from './actionType'
import { getSessionStorage } from '../../windowManager'
import { SEARCH_OPTION } from '../../globalConstants'

export const initialState = {
  searchedWorkOrders: [],
  searchedStoreIdWorkOrders: [],
  searchOption: getSessionStorage().getItem(SEARCH_OPTION) || 'woNum',
}

export default function searchedWorkOrdersReducer(
  state = initialState,
  action = {},
) {
  switch (action.type) {
    case SET_SEARCHED_WORK_ORDERS: {
      const newState = {
        ...state,
        searchedWorkOrders: action.searchedWorkOrders,
      }
      return newState
    }
    case SET_SEARCHED_STOREID_WORK_ORDERS: {
      const newState = {
        ...state,
        searchedStoreIdWorkOrders: action.searchedStoreIdWorkOrders,
      }
      return newState
    }
    case SET_SEARCH_OPTION: {
      const searchOption = action.searchOption
      const searchOptionState = {
        ...state,
        searchOption: searchOption,
      }
      getSessionStorage().setItem(SEARCH_OPTION, searchOption)
      return searchOptionState
    }
    default:
      return state
  }
}
