import AssignmentReturnIcon from '@material-ui/icons/AssignmentReturn'
import { connect } from 'react-redux'
import moment from 'moment'
import {
  setFollowUpActive,
  setFollowUpReason,
  setFollowUpReasonError,
} from './FollowUpReasonDropdown/actionCreator'
import ActionButton from '../ActionButton/ActionButton'
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn'
import { withApollo } from 'react-apollo'
import { withRouter } from 'react-router-dom'
import { gql } from 'apollo-boost'
import { setMessage } from '../GeoLocation/actionCreator'
import {
  ATTEMPTING_GPS,
  NO_CACHE,
  FOLLOW_UP_REASON,
  FOLLOW_UP,
  CHECK_OUT,
  UTC_FORMAT,
  GEO_LOCATION_UNSUPPORTED,
} from '../../globalConstants'
import { toggleRender } from '../MatLinearProgress/actionCreator'
import {
  geoLocationInNavigator,
  getURLSearchParams,
  getCompanyId,
} from '../../windowManager'
import {
  setRemedyErrorValidation,
  setCauseErrorValidation,
  setVisitNotesErrorValidation,
} from './FixedButton/actionCreator'
import { setCurrentPosition } from '../GeoLocation/actionCreator'

export const UPDATE_VISIT = gql`
  mutation UpdateVisit($visit: VisitInput!, $visitId: String!) {
    updateVisit(visit: $visit, visitId: $visitId) {
      visitId
    }
  }
`

export const FollowUpButton = ({
  client,
  setMessage,
  toggleRender,
  followUpActive,
  followUpReason,
  minCommentLengthReached,
  setFollowUpReason,
  setFollowUpActive,
  setFollowUpReasonError,
  setRemedyErrorValidation,
  setCauseErrorValidation,
  setVisitNotesErrorValidation,
  history,
}) => {
  const iconImage = followUpActive ? (
    <AssignmentTurnedInIcon />
  ) : (
    <AssignmentReturnIcon />
  )
  const onClickFollowUpButton = () => {
    // Show all fields which require data after the fixed or follow up button is clicked
    // https://jira.target.com/browse/PRMM-7900
    showRequiredFields()

    if (!followUpActive) {
      setFollowUpActive(true)
    } else {
      if (minCommentLengthReached && followUpReason) {
        setMessage(ATTEMPTING_GPS)
        toggleRender(true)
        geoLocationInNavigator()
          ? navigator.geolocation.getCurrentPosition(success, error)
          : notSupported()
      }
    }
  }

  const showRequiredFields = () => {
    setCauseErrorValidation(false)
    setRemedyErrorValidation(false)
    !minCommentLengthReached && setVisitNotesErrorValidation(true)
    !followUpReason && setFollowUpReasonError(true)
  }

  const updateFollowUpReason = async (lat = 0.0, lon = 0.0) => {
    const searchParams = getURLSearchParams()
    const visitId = searchParams.get('visit_id')

    let reasonResponse = {
      data: {
        updateVisit: {},
      },
    }
    let message = null
    try {
      setMessage(`Updating ${FOLLOW_UP_REASON} for visit #${visitId}`)
      const body = {
        visit_status: FOLLOW_UP,
        follow_up_reason: followUpReason,
        check_out_latitude: lat,
        check_out_longitude: lon,
      }
      reasonResponse = await client.mutate({
        mutation: UPDATE_VISIT,
        variables: {
          visit: body,
          visitId: visitId,
        },
        fetchPolicy: NO_CACHE,
      })
    } catch (e) {
      message = `Unable to update ${FOLLOW_UP_REASON} for visit #${visitId}`
    } finally {
      toggleRender(false)
      reasonResponse.data.updateVisit.visitId
        ? setMessage(
            `Successfully updated ${FOLLOW_UP_REASON} for visit #${visitId}`,
          )
        : setMessage(message)
      setFollowUpReason('')
      setFollowUpActive(false)
      setFollowUpReasonError(false)
    }
  }

  const checkOut = async (lat = 0.0, lon = 0.0) => {
    let response = {
      data: {
        updateVisit: {},
      },
    }
    let message = null
    const searchParams = getURLSearchParams()
    const visitId = searchParams.get('visit_id')
    try {
      setMessage(`Attempting to ${CHECK_OUT} of visit #${visitId}`)
      const body = {
        check_out_time: moment().utc().format(UTC_FORMAT),
        visit_status: CHECK_OUT,
        check_out_latitude: lat,
        check_out_longitude: lon,
      }
      response = await client.mutate({
        mutation: UPDATE_VISIT,
        variables: {
          visit: body,
          visitId: visitId,
        },
        fetchPolicy: NO_CACHE,
      })
      if (response.data.updateVisit.visitId && followUpActive) {
        await updateFollowUpReason(visitId, lat, lon)
      }
    } catch (e) {
      message = `Unable to ${CHECK_OUT} of visit #${visitId}`
    } finally {
      toggleRender(false)
      if (response.data.updateVisit.visitId) {
        message = null
        history.push(`/check-out-confirmation#?company_id=${getCompanyId()}`)
      }
      setMessage(message)
      setCurrentPosition(lat, lon)
    }
  }

  const success = (position) => {
    setMessage()
    checkOut(position.coords.latitude, position.coords.longitude)
  }

  const error = (error) => {
    setMessage(error.message)
  }

  const notSupported = () => {
    setMessage(GEO_LOCATION_UNSUPPORTED)
  }

  return (
    <ActionButton
      text={FOLLOW_UP}
      onClick={() => onClickFollowUpButton()}
      icon={iconImage}
    />
  )
}

const mapStateToProps = (state) => {
  return {
    followUpActive: state.followUpReasonReducer.followUpActive,
    followUpReason: state.followUpReasonReducer.followUpReason,
    minCommentLengthReached: state.visitCommentReducer.minCommentLengthReached,
    showFollowUpReasonErrorValidation:
      state.followUpReasonReducer.showFollowUpReasonErrorValidation,
  }
}

const mapDispatchToProps = {
  setFollowUpActive,
  setMessage,
  toggleRender,
  setFollowUpReason,
  setFollowUpReasonError,
  setRemedyErrorValidation,
  setCauseErrorValidation,
  setVisitNotesErrorValidation,
}

export default withApollo(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(FollowUpButton)),
)
