import { Component } from 'react'
import AppHeader from '../Header/Header'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import CheckOutButton from './CheckOutButton'
import VisitComments from '../WorkOrder/VisitComments/VisitComments'
import { Typography } from '@material-ui/core'
import WorkOrderDetailButton from './WorkOrderDetailButton'
import PhotoPageButton from './PhotoPageButton'
import FollowUpButton from './FollowUpButton'
import FixedButton from './FixedButton/FixedButton'
import FollowUpReasonDropdown from './FollowUpReasonDropdown/FollowUpReasonDropdown'
import { getURLSearchParams } from '../../windowManager'
import CancelButton from './CancelButton'
import { connect } from 'react-redux'
import { setFollowUpActive } from './FollowUpReasonDropdown/actionCreator'
import CauseDropdown from '../VisitDetail/CauseDropdown/CauseDropdown'
import RemedyDropdown from '../VisitDetail/RemedyDropdown/RemedyDropdown'
import { setMessage } from '../UserFeedback/actionCreator'
import {
  VINPRG,
  PAPPR,
  PDEC,
  PREVIEW,
  REPAIR_BY,
  UPLOAD_PHOTOS,
} from '../../globalConstants'
import UtcTimeToLocal from '../ConvertUtcTimeToLocal/UtcTimeToLocal'
import { resetFixedErrorValidation } from './FixedButton/actionCreator'
import { setFollowUpReasonError } from './FollowUpReasonDropdown/actionCreator'
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto'

const styles = (theme) => ({
  gridParent: {
    backgroundColor: theme.palette.secondary.action,
    padding: theme.spacing(1),
  },
  gridChild: {
    flexGrow: 1,
    padding: theme.spacing(1),
  },
  gridButtons: {
    padding: theme.spacing(0.5),
  },
  expirationPadding: {
    paddingBottom: theme.spacing(4),
  },
})

export class VisitDetail extends Component {
  componentWillUnmount = () => {
    const { resetFixedErrorValidation, setFollowUpReasonError } = this.props
    resetFixedErrorValidation()
    setFollowUpReasonError(false)
  }

  render = () => {
    const { classes, setMessage, setFollowUpActive } = this.props
    const searchParams = getURLSearchParams()
    const woStatus = searchParams.get('status')
    const workOrderNumber = searchParams.get('work_order_number')
    const location = searchParams.get('location')
    const visitId = searchParams.get('visit_id')
    const visitExpiration = searchParams.get('visit_expiration')
    const expectedRepairTime = searchParams.get('expected_repair_time')

    let content = null
    let saveButtonToggle = true

    setFollowUpActive(false)

    const statuses = [VINPRG, PAPPR, PREVIEW, PDEC]
    if (statuses.indexOf(woStatus) === -1) {
      setMessage(
        'Your Work Order status is being updated please check back again later',
      )
      saveButtonToggle = false
    } else {
      content = (
        <>
          <Grid container className={classes.gridParent}>
            <Grid item className={classes.gridChild} xs={12}>
              <Typography variant={'h6'}>{location}</Typography>
            </Grid>
            <Grid item className={`${classes.gridChild}`} xs={12}>
              <UtcTimeToLocal text={REPAIR_BY} date={expectedRepairTime} />
            </Grid>
            <Grid
              item
              className={`${classes.gridChild} ${classes.expirationPadding}`}
              xs={12}
            >
              <UtcTimeToLocal date={visitExpiration} />
            </Grid>
            <Grid item className={classes.gridChild} xs={12}>
              <VisitComments visitId={visitId} errorMessageShown />
            </Grid>
            <Grid item className={classes.gridChild} xs={12}>
              <CauseDropdown />
            </Grid>
            <Grid item className={classes.gridChild} xs={12}>
              <RemedyDropdown />
            </Grid>
            <Grid item className={classes.gridChild} xs={12}>
              <FollowUpReasonDropdown />
            </Grid>
            <Grid item className={classes.gridChild} align={'right'}>
              <CheckOutButton />
              <FollowUpButton />
              <CancelButton />
              <FixedButton />
            </Grid>
          </Grid>
          <Grid container className={classes.gridButtons}>
            <Grid item className={classes.gridChild} xs={12}>
              <PhotoPageButton
                text={UPLOAD_PHOTOS}
                icon={<AddAPhotoIcon />}
                baseUrl="/image-upload"
              />
            </Grid>
            <Grid item className={classes.gridChild} xs={12}>
              <PhotoPageButton />
            </Grid>
            <Grid item className={classes.gridChild} xs={12}>
              <WorkOrderDetailButton />
            </Grid>
          </Grid>
        </>
      )
    }
    return (
      <>
        <AppHeader
          arrowBack
          title={`WO #${workOrderNumber}`}
          saveButton={saveButtonToggle}
        />
        {content}
      </>
    )
  }
}

const mapDispatchToProps = {
  setFollowUpActive,
  setMessage,
  resetFixedErrorValidation,
  setFollowUpReasonError,
}

export default withStyles(styles)(
  connect(null, mapDispatchToProps)(VisitDetail),
)
