import {
  RESET_ERROR_FIXED_BUTTON,
  SET_ERROR_REMEDY_FIXED_BUTTON,
  SET_ERROR_CAUSE_FIXED_BUTTON,
  SET_ERROR_VISIT_NOTES_FIXED_BUTTON,
} from './actionType'

export const initialState = {
  showFixedButtonErrorValidation: false,
  showRemedyErrorValidation: false,
  showCauseErrorValidation: false,
  showVisitNoteErrorValidation: false,
}

export default function fixedButtonReducer(state = initialState, action = {}) {
  switch (action.type) {
    case RESET_ERROR_FIXED_BUTTON: {
      const newState = {
        ...state,
        showCauseErrorValidation: false,
        showRemedyErrorValidation: false,
        showVisitNoteErrorValidation: false,
      }
      return newState
    }
    case SET_ERROR_CAUSE_FIXED_BUTTON: {
      const newState = {
        ...state,
        showCauseErrorValidation: action.showCauseErrorValidation,
      }
      return newState
    }
    case SET_ERROR_REMEDY_FIXED_BUTTON: {
      const newState = {
        ...state,
        showRemedyErrorValidation: action.showRemedyErrorValidation,
      }
      return newState
    }
    case SET_ERROR_VISIT_NOTES_FIXED_BUTTON: {
      const newState = {
        ...state,
        showVisitNoteErrorValidation: action.showVisitNoteErrorValidation,
      }
      return newState
    }
    default:
      return state
  }
}
