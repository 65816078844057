import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Input from '@material-ui/core/Input'
import { connect } from 'react-redux'
import { setProfileName } from './actionCreator'
import { NAME, NAME_CHARACTERS_LENGTH } from '../../globalConstants'
import MaskedInput from 'react-text-mask'
import { setEditProfileName } from '../EditProfile/actionCreator'

const nameRegEx = /^[a-z ,'-]+$/i

const maskFunction = (rawValue) => {
  const mask = new Array(rawValue.length)
  mask.fill(nameRegEx)
  return mask
}

const TextMaskCustom = (props) => {
  const { inputRef, ...other } = props
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref && ref.inputElement)
      }}
      mask={maskFunction}
      guide={false}
    />
  )
}

const NameInput = ({
  setProfileName,
  profileName,
  editedProfileName,
  createProfile,
  editProfile,
  setEditProfileName,
}) => {
  const handleNameChange = () => (event) => {
    const eventTargetValue = event.target.value.trim()
    createProfile && setProfileName(eventTargetValue)
    editProfile && setEditProfileName(eventTargetValue)
  }

  return (
    <FormControl required fullWidth>
      <InputLabel>{NAME}</InputLabel>
      <Input
        onChange={handleNameChange()}
        inputComponent={TextMaskCustom}
        value={createProfile ? profileName : editedProfileName}
        inputProps={{ maxLength: NAME_CHARACTERS_LENGTH, 'aria-label': NAME }}
        autoFocus
      />
    </FormControl>
  )
}

const mapStateToProps = (state) => {
  return {
    profileName: state.createProfileReducer.profileName,
    editedProfileName: state.editProfileReducer.editedProfileName,
  }
}

const mapDispatchToProps = {
  setProfileName,
  setEditProfileName,
}

export default connect(mapStateToProps, mapDispatchToProps)(NameInput)
