import { SET_MESSAGE } from './actionType'

const initialState = {
  message: null,
}

export default function userFeedbackReducer(state = initialState, action) {
  switch (action.type) {
    case SET_MESSAGE: {
      const actionMessage = action.message
      let open = false
      if (actionMessage) {
        open = true
      }
      const newState = {
        message: action.message,
        open: open,
      }
      return newState
    }
    default:
      return state
  }
}
