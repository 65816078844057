import { TOGGLE_RENDER } from './actionType'

const initialState = {
  shouldRender: false,
}

export default function linearProgressReducer(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_RENDER: {
      const newState = {
        shouldRender: action.shouldRender,
      }
      return newState
    }
    default:
      return state
  }
}
