import { Component } from 'react'
import AppHeader from '../Header/Header'
import WorkOrder from '../WorkOrder/WorkOrder'
import Grid from '@material-ui/core/Grid'
import { connect } from 'react-redux'
import {
  setSearchedWorkOrders,
  setSearchedStoreIdWorkOrders,
} from '../Search/actionCreator'
import { getURLSearchParams, getCompanyId } from '../../windowManager'
import { setMessage } from '../UserFeedback/actionCreator'
import { toggleRender } from '../MatLinearProgress/actionCreator'
import {
  NO_RESULTS,
  LOADING_WORK_ORDER,
  LOADING_WORK_ORDERS,
  NO_CACHE,
  VREPAIR,
  WO_IN_VREPAIR,
  INVALID_GPS_BODY,
  INVALID_GPS_TITLE,
  ERROR_WO_FOR_STORE,
  NO_WORKORDERS_FOR_STORE,
  CHECK_IN,
} from '../../globalConstants'
import { withApollo } from 'react-apollo'
import { gql } from 'apollo-boost'
import Profiles from '../Profiles/Profiles'
import CompanySearchOptions from './CompanySearchOptions'
import InlineFeedback from '../InlineFeedback/InlineFeedback'
import WarningIcon from '@material-ui/icons/Warning'
import WorkOrderList from '../WorkOrder/WorkOrderList'
import { ListSubheader } from '@material-ui/core'
import { setSearchOption } from './actionCreator'
import { setCheckInGeofence } from '../MyWork/actionCreator'

export const GET_WORK_ORDER = gql`
  query GetWorkOrder(
    $woNum: String!
    $companyId: String!
    $technicianId: String!
  ) {
    getWorkOrder(
      woNum: $woNum
      companyId: $companyId
      technicianId: $technicianId
    ) {
      workOrderNumber
      locationId
      problemCode
      assetName
      status
      visits {
        visitId
        sourceId
        referenceId
        checkInTime
        visitStatus
        visitExpiration
      }
      pmContract {
        responseHours
      }
      location {
        addresses {
          city
          region
          postalCode
          address
        }
        locations {
          locationName
        }
      }
      expectedResponseTime
      expectedRepairTime
      companyId
      companyName
    }
  }
`

export const GET_WORK_ORDERS_BY_STORE = gql`
  query GetWorkOrdersByStore($companyId: String!, $storeId: String!) {
    getWorkOrdersByStore(companyId: $companyId, locationId: $storeId) {
      workOrderNumber
      locationId
      location {
        addresses {
          city
          region
          postalCode
          address
        }
        locations {
          locationName
        }
      }
      problemCode
      assetName
      status
      expectedResponseTime
      companyId
      companyName
    }
  }
`

export class Search extends Component {
  getWorkOrderDataByTech = async () => {
    const {
      setSearchedWorkOrders,
      session,
      setMessage,
      toggleRender,
      client,
      checkInGeofence,
      history,
      setCheckInGeofence,
    } = this.props

    setSearchedWorkOrders([])
    const searchParams = getURLSearchParams()
    const workOrderNumber = searchParams.get('wonum')
    let message = null

    if (workOrderNumber) {
      try {
        toggleRender(true)
        setMessage(LOADING_WORK_ORDER)
        const technicianId = session.userInfo.technicianId
        const result = await client.query({
          query: GET_WORK_ORDER,
          variables: {
            woNum: workOrderNumber,
            companyId: getCompanyId(),
            technicianId: technicianId,
          },
          fetchPolicy: NO_CACHE,
        })
        const resultWO = result.data.getWorkOrder

        if (resultWO[0].status === VREPAIR) {
          message = WO_IN_VREPAIR
        } else {
          setSearchedWorkOrders(resultWO)
        }

        const geoStatus = checkInGeofence?.toLowerCase()
        switch (geoStatus) {
          case 'outside':
            setCheckInGeofence(undefined)
            history.push(
              `/outside-geofence-screen#?company_id=${getCompanyId()}&wonum=${workOrderNumber}&visit_id=${
                resultWO[0].visits[0].visitId
              }`,
            )
            break
          case 'inside':
            setCheckInGeofence(undefined)
            message = `Successful ${CHECK_IN} inside geofence to work order #${workOrderNumber}`
            break
          default:
            //do nothing
            break
        }
      } catch (e) {
        message = NO_RESULTS
      } finally {
        toggleRender(false)
      }
      setMessage(message)
    }
  }

  getWorkOrdersByStore = async () => {
    const { setMessage, toggleRender, client, setSearchedStoreIdWorkOrders } =
      this.props

    setSearchedStoreIdWorkOrders([])
    const searchParams = getURLSearchParams()
    const companyId = getCompanyId()
    const storeId = searchParams.get('store_id')
    let message = null

    if (companyId && storeId) {
      try {
        toggleRender(true)
        setMessage(LOADING_WORK_ORDERS)
        const result = await client.query({
          query: GET_WORK_ORDERS_BY_STORE,
          variables: {
            companyId: companyId,
            storeId: storeId,
          },
          fetchPolicy: NO_CACHE,
        })
        const resultWOs = result.data.getWorkOrdersByStore
        setSearchedStoreIdWorkOrders(resultWOs)
        message = !resultWOs.length && NO_WORKORDERS_FOR_STORE
      } catch (e) {
        message = ERROR_WO_FOR_STORE
      } finally {
        toggleRender(false)
      }
      setMessage(message)
    }
  }

  determineLoad = () => {
    const { searchOption } = this.props
    searchOption === 'woNum'
      ? this.getWorkOrderDataByTech()
      : this.getWorkOrdersByStore()
  }

  componentWillUnmount = () => {
    window.removeEventListener('hashchange', this.determineLoad)
  }

  componentDidMount = () => {
    window.addEventListener('hashchange', this.determineLoad)
    setTimeout(this.determineLoad, 1)
  }

  componentDidUpdate = (prevProps) => {
    const { session } = this.props
    prevProps.session.userInfo.technicianId !== session.userInfo.technicianId &&
      this.getWorkOrderDataByTech()
  }

  getWorkOrder = () => {
    const { searchedWorkOrders } = this.props
    return searchedWorkOrders.length ? (
      /* istanbul ignore next */ <>
        <Grid container>
          <Grid item>
            <InlineFeedback
              icon={<WarningIcon />}
              title={INVALID_GPS_TITLE}
              body={INVALID_GPS_BODY}
            />
          </Grid>
          {searchedWorkOrders.map((searchedWorkOrder, key) => (
            <WorkOrder workOrder={searchedWorkOrder} key={key} />
          ))}
        </Grid>
        <Profiles />
      </>
    ) : null
  }

  getWorkOrdersList = (storeId) => {
    const { searchedStoreIdWorkOrders, setSearchOption } = this.props
    setSearchOption('storeId')
    return searchedStoreIdWorkOrders.length ? (
      <>
        <ListSubheader>Showing Results for store {storeId}</ListSubheader>
        <Grid container>
          {searchedStoreIdWorkOrders.map((searchedStoreIdWorkOrders, key) => (
            <WorkOrderList workOrders={searchedStoreIdWorkOrders} key={key} />
          ))}
        </Grid>
      </>
    ) : null
  }

  render = () => {
    const searchParams = getURLSearchParams()
    const workOrderNumber = searchParams.get('wonum')
    const storeId = searchParams.get('store_id')
    let content = <CompanySearchOptions />
    if (storeId) {
      content = this.getWorkOrdersList(storeId)
    } else if (workOrderNumber) {
      content = this.getWorkOrder()
    }
    return (
      <>
        <AppHeader arrowBack searchInput />
        {content}
      </>
    )
  }
}

export const mapStateToProps = (state) => {
  return {
    searchedWorkOrders: state.searchedWorkOrdersReducer.searchedWorkOrders,
    session: state.authCredentialsReducer.auth.session,
    searchOption: state.searchedWorkOrdersReducer.searchOption,
    searchedStoreIdWorkOrders:
      state.searchedWorkOrdersReducer.searchedStoreIdWorkOrders,
    checkInGeofence: state.myWorkOrdersReducer.checkInGeofence,
  }
}

const mapDispatchToProps = {
  setSearchedWorkOrders,
  setMessage,
  toggleRender,
  setSearchedStoreIdWorkOrders,
  setSearchOption,
  setCheckInGeofence,
}

export default withApollo(connect(mapStateToProps, mapDispatchToProps)(Search))
