import { Typography } from '@material-ui/core'
import { Component } from 'react'
import { connect } from 'react-redux'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import { withStyles } from '@material-ui/core/styles'
import { getURLSearchParams, getCompanyId } from '../../windowManager'

const styles = (theme) => ({
  accountCircleIcon: {
    marginRight: theme.spacing(1),
  },
  nameWrapper: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'flex',
  },
})

export class ProfileInfo extends Component {
  constructor() {
    super()
    this.state = {
      renderProfile: true,
    }
  }

  componentWillUnmount = () => {
    window.removeEventListener('hashchange', this.readParams)
  }

  componentDidMount = () => {
    window.addEventListener('hashchange', this.readParams)
    this.readParams()
  }

  readParams = () => {
    const searchParams = getURLSearchParams()
    const path = window.location.pathname
    const wonum = searchParams.get('wonum')
    const storeId = searchParams.get('store_id')
    const work_order_number = searchParams.get('work_order_number')
    const companyId = getCompanyId()
    const visit_id = searchParams.get('visit_id')
    const onValidPages =
      path === '/create-profile' || path === '/edit-profile' || path === '/faq'
    const renderProfile =
      (companyId && wonum) ||
      (work_order_number && companyId) ||
      visit_id ||
      (companyId && storeId) ||
      onValidPages
    this.setState({ renderProfile: renderProfile })
  }

  render = () => {
    const { classes, session } = this.props
    const name = session.userInfo.name
    return (
      // are the render critera met AND do we have a valid name?
      // go ahead and render the name / avatar
      // otherwise, render nothing
      this.state.renderProfile &&
      name && (
        <div className={classes.nameWrapper}>
          <AccountCircleIcon className={classes.accountCircleIcon} />
          <Typography noWrap>{name}</Typography>
        </div>
      )
    )
  }
}

const mapStateToProps = (state) => {
  return {
    session: state.authCredentialsReducer.auth.session,
  }
}

export default connect(mapStateToProps, null)(withStyles(styles)(ProfileInfo))
