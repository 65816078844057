import { withStyles } from '@material-ui/core/styles'
import { Paper } from '@material-ui/core'
import Radio from '@material-ui/core/Radio'
import CreateIcon from '@material-ui/icons/Create'
import IconButton from '@material-ui/core/IconButton'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { getCompanyId } from '../../windowManager'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

const useStyles = (theme) => ({
  paper: {
    position: 'relative',
    padding: theme.spacing(0.5, 2),
  },
  iconButton: {
    position: 'absolute',
    right: theme.spacing(0.1),
    top: theme.spacing(0.1),
  },
  formControlLabel: {
    width: '100%',
  },
})

const ClickablePaper = ({ value, classes, text, session, history }) => {
  return (
    <Paper className={classes.paper}>
      <FormControlLabel
        className={classes.formControlLabel}
        value={value}
        control={<Radio color="default" />}
        label={text}
      />
      {session.userInfo.technicianId === value && (
        <IconButton
          color="primary"
          className={classes.iconButton}
          aria-label="Edit Profile"
          onClick={() =>
            history.push(
              `/edit-profile#?company_id=${getCompanyId()}&profile_id=${value}`,
            )
          }
        >
          <CreateIcon />
        </IconButton>
      )}
    </Paper>
  )
}

const mapStateToProps = (state) => {
  return {
    session: state.authCredentialsReducer.auth.session,
  }
}

export default withStyles(useStyles)(
  connect(mapStateToProps, null)(withRouter(ClickablePaper)),
)
