import AppHeader from '../Header/Header'
import { SERVICE_TECH } from '../../globalConstants'
import HomePageMessage from './HomePageMessage'

const Home = () => {
  return (
    <>
      <AppHeader menuAction searchAction title={SERVICE_TECH} />
      <HomePageMessage />
    </>
  )
}

export default Home
