import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import { withStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import Divider from '@material-ui/core/Divider'
import CardContent from '@material-ui/core/CardContent'
import { SERVICE_TECH, LINKS_MESSAGE } from '../../globalConstants'

const styles = (theme) => ({
  card: {
    margin: theme.spacing(1),
  },
  cardHeader: {
    padding: theme.spacing(1, 0.5, 1, 2),
  },
})

const HomePageMessage = ({ classes }) => {
  return (
    <Card className={classes.card}>
      <CardHeader
        title={
          <Typography variant={'h6'}>Welcome to {SERVICE_TECH}</Typography>
        }
        className={classes.cardHeader}
        data-cy="welcome-message"
      />
      <Divider variant={'middle'} />
      <CardContent>
        <Typography paragraph>
          {`You navigated to an unused page in ServiceTech. ${LINKS_MESSAGE}`}
        </Typography>
      </CardContent>
    </Card>
  )
}

export default withStyles(styles)(HomePageMessage)
