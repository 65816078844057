import { Component } from 'react'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { gql } from 'apollo-boost'
import { withApollo } from 'react-apollo'
import { connect } from 'react-redux'
import { setFollowUpReason } from './actionCreator'
import {
  LOADING,
  FAILED_TO_FETCH,
  NO_CACHE,
  FOLLOW_UP_REASON,
} from '../../../globalConstants'
import FormHelperText from '@material-ui/core/FormHelperText'

export const getFollowUpReasonQuery = gql`
  query GetFollowUpReasons {
    getFollowUpReasons {
      followUpReason
    }
  }
`
export class FollowUpReasonDropdown extends Component {
  constructor() {
    super()
    this.state = {
      followUpReasons: [],
      displayMessage: LOADING,
    }
  }

  componentDidMount = async () => {
    const { client } = this.props
    try {
      const reasons = await client.query({
        query: getFollowUpReasonQuery,
        variables: {},
        fetchPolicy: NO_CACHE,
      })
      const followUpReasons = reasons.data.getFollowUpReasons

      /* istanbul ignore next */ this.setState(
        followUpReasons.length
          ? {
              followUpReasons: followUpReasons.map(
                (followUpObject) => followUpObject.followUpReason,
              ),
            }
          : {
              displayMessage: `No ${FOLLOW_UP_REASON}s available`,
            },
      )
    } catch (e) {
      this.setState({
        displayMessage: FAILED_TO_FETCH,
      })
    }
  }

  handleChange = (event) => {
    const { setFollowUpReason } = this.props
    const reason = event.target.value
    this.setState({
      reason: reason,
    })
    setFollowUpReason(reason)
  }

  render = () => {
    const followUpReasonsLength = this.state.followUpReasons.length
    const {
      followUpActive,
      followUpReason,
      showFollowUpReasonErrorValidation,
    } = this.props
    return (
      followUpActive && (
        <FormControl
          fullWidth
          error={showFollowUpReasonErrorValidation && !followUpReason}
        >
          <InputLabel>
            {followUpReasonsLength
              ? FOLLOW_UP_REASON
              : this.state.displayMessage}
          </InputLabel>
          <Select
            value={followUpReason || ''}
            onChange={this.handleChange}
            disabled={!followUpReasonsLength}
          >
            <MenuItem value={''} />
            {this.state.followUpReasons.map((reason, index) => {
              return (
                <MenuItem value={reason} key={index}>
                  {reason}
                </MenuItem>
              )
            })}
          </Select>
          {showFollowUpReasonErrorValidation && !followUpReason && (
            <FormHelperText>*Please choose a follow up reason</FormHelperText>
          )}
        </FormControl>
      )
    )
  }
}

const mapStateToProps = (state) => {
  return {
    followUpActive: state.followUpReasonReducer.followUpActive,
    followUpReason: state.followUpReasonReducer.followUpReason,
    showFollowUpReasonErrorValidation:
      state.followUpReasonReducer.showFollowUpReasonErrorValidation,
  }
}

const mapDispatchToProps = {
  setFollowUpReason,
}

export default withApollo(
  connect(mapStateToProps, mapDispatchToProps)(FollowUpReasonDropdown),
)
