import {
  SET_INITIAL_PROFILE_NAME,
  SET_INITIAL_PROFILE_PHONE,
  SET_EDIT_PROFILE_NAME,
  SET_EDIT_PROFILE_PHONE,
  SET_EDIT_PROFILE_ID,
} from './actionType'
import {
  MIN_NAME_LENGTH,
  MIN_PHONENUMBER_LENGTH,
  MAX_PHONENUMBER_LENGTH,
} from '../../globalConstants'

export const initialState = {
  profileId: '',
  profileName: '',
  profilePhone: '',
  editedProfileName: '',
  editedProfilePhone: '',
  minNameLengthReached: false,
  maxPhoneNumberLengthReached: false,
}

export default function editProfileReducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_INITIAL_PROFILE_NAME: {
      const newState = {
        ...state,
        profileName: action.profileName,
        editedProfileName: action.profileName,
        minNameLengthReached: action.profileName.length >= MIN_NAME_LENGTH,
      }
      return newState
    }
    case SET_INITIAL_PROFILE_PHONE: {
      const newState = {
        ...state,
        profilePhone: action.profilePhone,
        editedProfilePhone: action.profilePhone,
        maxPhoneNumberLengthReached:
          action.profilePhone.length >= MAX_PHONENUMBER_LENGTH ||
          action.profilePhone.length === MIN_PHONENUMBER_LENGTH,
      }
      return newState
    }
    case SET_EDIT_PROFILE_NAME: {
      const newState = {
        ...state,
        editedProfileName: action.profileName,
        minNameLengthReached: action.profileName.length >= MIN_NAME_LENGTH,
      }
      return newState
    }
    case SET_EDIT_PROFILE_PHONE: {
      const newState = {
        ...state,
        editedProfilePhone: action.profilePhone,
        maxPhoneNumberLengthReached:
          action.profilePhone.length >= MAX_PHONENUMBER_LENGTH ||
          action.profilePhone.length === MIN_PHONENUMBER_LENGTH,
      }
      return newState
    }
    case SET_EDIT_PROFILE_ID: {
      const newState = {
        ...state,
        profileId: action.profileId,
      }
      return newState
    }
    default:
      return state
  }
}
