import { SET_VISIT_COMMENTS } from './actionType'

export const initialState = {
  visitComments: '',
  minCommentLengthReached: false,
}

export default function visitCommentReducer(state = initialState, action) {
  switch (action.type) {
    case SET_VISIT_COMMENTS: {
      const newState = {
        visitComments: action.visitComments,
        minCommentLengthReached: action.visitComments.trim().length >= 3,
      }
      return newState
    }
    default:
      return state
  }
}
