import { setMessage } from '../GeoLocation/actionCreator'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import {
  setProfiles,
  setAuthCredentials,
} from '../../common/UserAuth/actionCreator'
import { getLocalStorage } from '../../windowManager'
import { PROFILES } from '../../globalConstants'

const DELETE = 'DELETE'

const ConfirmDeleteButton = ({
  setProfiles,
  setAuthCredentials,
  profileId,
  profiles,
  setMessage,
}) => {
  const onClickDelete = () => {
    const currentProfiles = JSON.parse(JSON.stringify(profiles)) || []
    const updatedProfiles = currentProfiles.filter((profile) => {
      return profile.id !== profileId
    })
    const storage = getLocalStorage()
    if (storage) {
      setMessage('Your profile has been deleted')
      const activeProfile = {
        session: {
          userInfo: {
            lanId: '000000',
            name: '',
            phone: '',
          },
        },
      }
      if (updatedProfiles.length) {
        const firstProfile = updatedProfiles[0]
        firstProfile.active = true
        const userInfo = activeProfile.session.userInfo
        userInfo.lanId = firstProfile.id
        userInfo.name = firstProfile.name
        userInfo.phone = firstProfile.phone
      }
      setAuthCredentials(activeProfile)
      storage.setItem(PROFILES, JSON.stringify(updatedProfiles))
      setProfiles(updatedProfiles)
      window.history.go(-2)
    } else {
      setMessage('Unable to delete profile due to Local Storage setting')
      window.history.back()
    }
  }

  return (
    <Button onClick={onClickDelete} aria-label={DELETE}>
      {DELETE}
    </Button>
  )
}

const mapStateToProps = (state) => {
  return {
    profileId: state.editProfileReducer.profileId,
    profiles: state.authCredentialsReducer.profiles,
  }
}

const mapDispatchToProps = {
  setMessage,
  setProfiles,
  setAuthCredentials,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(ConfirmDeleteButton))
