import PersonPinCircleIcon from '@material-ui/icons/PersonPinCircle'
import { setMessage } from '../GeoLocation/actionCreator'
import { connect } from 'react-redux'
import { toggleRender } from '../MatLinearProgress/actionCreator'
import moment from 'moment'
import { withApollo } from 'react-apollo'
import { gql } from 'apollo-boost'
import { geoLocationInNavigator, dispatchHashChange } from '../../windowManager'
import {
  GEO_LOCATION_UNSUPPORTED,
  ATTEMPTING_GPS,
  UTC_FORMAT,
  NO_CACHE,
  EN_ROUTE,
  CHECK_IN,
} from '../../globalConstants'
import {
  setVisitStatus,
  setWorkOrderStatus,
  setCheckInGeofence,
} from '../MyWork/actionCreator'
import ActionButton from '../ActionButton/ActionButton'
import { VINPRG } from '../../globalConstants'
import { setCurrentPosition } from '../GeoLocation/actionCreator'

export const UPDATE_VISIT = gql`
  mutation UpdateVisit(
    $visit: VisitInput!
    $visitId: String!
    $woStatus: String
  ) {
    updateVisit(visit: $visit, visitId: $visitId, woStatus: $woStatus) {
      visitId
      checkInTime
      visitStatus
      checkInLatitude
      checkInLongitude
      visitComments
      checkInGeofence
    }
  }
`

const CheckInButton = ({
  setMessage,
  toggleRender,
  workOrder,
  client,
  setVisitStatus,
  setWorkOrderStatus,
  session,
  setCurrentPosition,
  setCheckInGeofence,
}) => {
  const visitStatus = workOrder.visits[0].visitStatus
  const checkIn = async (lat = 0.0, lon = 0.0) => {
    let response = {
      data: {
        updateVisit: {
          visitId: '',
          visitStatus: '',
        },
      },
    }
    const workOrderNumber = workOrder.workOrderNumber
    // we assume we will fail
    let message = `Unable to ${CHECK_IN} to work order #${workOrderNumber}`
    try {
      setMessage(
        `Attempting to ${CHECK_IN} as ${session.userInfo.name} to work order #${workOrderNumber}`,
      )
      const body = {
        check_in_time: moment().utc().format(UTC_FORMAT),
        visit_status: CHECK_IN,
        check_in_latitude: lat,
        check_in_longitude: lon,
        reference_id: workOrderNumber,
      }
      response = await client.mutate({
        mutation: UPDATE_VISIT,
        variables: {
          visit: body,
          visitId: workOrder.visits[0].visitId,
          woStatus: workOrder.status,
        },
        fetchPolicy: NO_CACHE,
      })
      message =
        response.data.updateVisit.visitId &&
        `Successful ${CHECK_IN} to work order #${workOrderNumber}`
    } catch (e) {
      message = `Unable to ${CHECK_IN} to work order ${workOrderNumber}`
    } finally {
      toggleRender(false)
      setMessage(message)
      if (response.data.updateVisit.visitStatus) {
        setVisitStatus(workOrderNumber, response.data.updateVisit.visitStatus)
        setCheckInGeofence(response.data.updateVisit.checkInGeofence)
        setWorkOrderStatus(workOrderNumber, VINPRG)
        dispatchHashChange()
      }
      setCurrentPosition(lat, lon)
    }
  }

  const success = (position) => {
    setMessage()
    checkIn(position.coords.latitude, position.coords.longitude)
  }

  const error = (error) => {
    setMessage(error.message)
    checkIn()
  }

  const notSupported = () => {
    setMessage(GEO_LOCATION_UNSUPPORTED)
    checkIn()
  }

  const onClickCheckInButton = () => {
    setMessage(ATTEMPTING_GPS)
    toggleRender(true)
    geoLocationInNavigator()
      ? navigator.geolocation.getCurrentPosition(success, error)
      : notSupported()
  }

  return (
    visitStatus === EN_ROUTE && (
      <ActionButton
        text={`${CHECK_IN} as ${session.userInfo.name}`}
        fullWidth
        onClick={onClickCheckInButton}
        icon={<PersonPinCircleIcon />}
      />
    )
  )
}

const mapDispatchToProps = {
  setMessage,
  toggleRender,
  setVisitStatus,
  setWorkOrderStatus,
  setCurrentPosition,
  setCheckInGeofence,
}

const mapStateToProps = (state) => {
  return {
    session: state.authCredentialsReducer.auth.session,
  }
}

export default withApollo(
  connect(mapStateToProps, mapDispatchToProps)(CheckInButton),
)
