import { Component } from 'react'
import { string, bool } from 'prop-types'
import AppBar from '@material-ui/core/AppBar'
import IconButton from '@material-ui/core/IconButton'
// import MenuIcon from '@material-ui/icons/Menu'
import SearchIcon from '@material-ui/icons/Search'
import ArrowBack from '@material-ui/icons/ArrowBack'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { connect } from 'react-redux'
import { openSideNav } from '../../store/layout/actionCreator'
import { withStyles } from '@material-ui/core/styles'
// import { Link } from 'react-router-dom'
import InputBase from '@material-ui/core/InputBase'
import {
  getURLSearchParams,
  setURLSearchParams /*, isLocal*/,
  dispatchHashChange,
} from '../../windowManager'
import {
  WORK_ORDER_NUMBER_DIGIT_LENGTH,
  STORE_NUMBER_DIGIT_LENGTH,
} from '../../globalConstants'
import SaveButton from '../VisitDetail/SaveButton'
import {
  SEARCH_FOR_WORK_ORDER_NUMBER,
  BACK,
  SEARCH_BY_STORE_NUMBER,
} from '../../globalConstants'
import DeleteProfileButton from '../EditProfile/DeleteProfileButton'
import TargetLogo from '../../images/targetlogo.png'

const styles = (theme) => ({
  leftIconButton: {
    marginRight: theme.spacing(0.5),
    marginLeft: theme.spacing(-1.5),
    color: theme.palette.secondary.action,
  },
  rightIconButton: {
    color: theme.palette.secondary.action,
  },
  logo: {
    marginRight: theme.spacing(2),
  },
  inputRoot: {
    color: 'inherit',
    flexGrow: 1,
    verticalAlign: 'bottom',
    '& input::placeholder': {
      opacity: 0.9,
    },
  },
  inputInput: {
    padding: theme.spacing(1),
  },
  searchIcon: {
    opacity: 0.62,
  },
})

export class Header extends Component {
  constructor() {
    super()
    this.state = {
      workOrderNumber: null,
      input: '',
    }
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.searchOption !== this.props.searchOption) {
      const wonum = getURLSearchParams().get('wonum')
      this.setState({ input: wonum ? wonum : '' })
      dispatchHashChange()
    }
  }

  onHashChange = () => {
    const searchParams = getURLSearchParams()
    const storeID = searchParams.get('store_id')
    const woNum = searchParams.get('wonum')
    const input = this.props.searchOption === 'storeId' ? storeID : woNum
    this.setState({
      workOrderNumber: woNum,
      siteId: storeID,
      visitId: searchParams.get('visit_id'),
      input: input,
    })
  }

  componentWillUnmount = () => {
    window.removeEventListener('hashchange', this.onHashChange)
  }

  componentDidMount = () => {
    window.addEventListener('hashchange', this.onHashChange)
    this.onHashChange()
  }

  render = () => {
    const {
      title,
      // menuAction,
      arrowBack,
      // openSideNav,
      classes,
      // searchAction,
      searchInput,
      saveButton,
      deleteProfileButton,
      searchOption,
    } = this.props

    const handleArrowBackClick = () => {
      window.history.back()
    }

    const onChangeInputBase = (event) => {
      const eventTargetValue = event.target.value.replace(/\D/g, '')
      let searchInput = eventTargetValue
      let searchParams = eventTargetValue.length ===
        WORK_ORDER_NUMBER_DIGIT_LENGTH && [
        { name: 'wonum', value: eventTargetValue },
      ]
      if (searchOption === 'storeId') {
        searchInput = `T${eventTargetValue}`
        eventTargetValue.length === STORE_NUMBER_DIGIT_LENGTH - 1 &&
          (searchParams = [{ name: 'store_id', value: `T${eventTargetValue}` }])
      }
      this.setState({ input: searchInput })
      setURLSearchParams(searchParams)
    }

    let placeholderText = SEARCH_FOR_WORK_ORDER_NUMBER
    let inputProps = {
      'aria-label': SEARCH_FOR_WORK_ORDER_NUMBER,
      maxLength: WORK_ORDER_NUMBER_DIGIT_LENGTH,
    }

    if (searchOption === 'storeId') {
      placeholderText = SEARCH_BY_STORE_NUMBER
      inputProps = {
        'aria-label': SEARCH_BY_STORE_NUMBER,
        maxLength: STORE_NUMBER_DIGIT_LENGTH,
      }
    }

    return (
      <>
        <AppBar position={'fixed'}>
          <Toolbar>
            {/* lets remove this for now, but leave the code in case we want to re-enable in the future */}
            {/* {menuAction && isLocal() && (
              <IconButton
                className={classes.leftIconButton}
                onClick={openSideNav}
                aria-label={'Menu'}
              >
                <MenuIcon />
              </IconButton>
            )} */}
            {arrowBack && (
              <IconButton
                onClick={handleArrowBackClick}
                aria-label={BACK}
                className={classes.leftIconButton}
              >
                <ArrowBack />
              </IconButton>
            )}
            {!searchInput && (
              <img
                src={TargetLogo}
                height={24}
                alt={'Target-Logo'}
                className={classes.logo}
              />
            )}
            {/* {searchAction && (
              <Link to={'/search'}>
                <IconButton
                  className={classes.rightIconButton}
                  data-cy="search-button"
                >
                  <SearchIcon />
                </IconButton>
              </Link>
            )} */}
            {searchInput ? (
              <>
                <SearchIcon className={classes.searchIcon} />
                <InputBase
                  placeholder={placeholderText}
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                  inputProps={inputProps}
                  onChange={onChangeInputBase}
                  data-cy="search-input"
                  value={this.state.input || ''}
                />
              </>
            ) : (
              <Typography variant={'h6'} style={{ flexGrow: 1 }}>
                {title}
              </Typography>
            )}
            {saveButton && <SaveButton visitId={this.state.visitId} />}
            {deleteProfileButton && <DeleteProfileButton />}
          </Toolbar>
        </AppBar>
      </>
    )
  }
}

Header.propTypes = {
  title: string.isRequired,
  menuAction: bool,
}

Header.defaultProps = {
  title: '',
}

const mapStateToProps = (state) => {
  return {
    searchOption: state.searchedWorkOrdersReducer.searchOption,
  }
}

const mapDispatchToProps = {
  openSideNav,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(Header))
