import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { setMessage } from '../../GeoLocation/actionCreator'
import { toggleRender } from '../../MatLinearProgress/actionCreator'
import { gql } from 'apollo-boost'
import moment from 'moment'
import { withApollo } from 'react-apollo'
import {
  geoLocationInNavigator,
  getURLSearchParams,
  getCompanyId,
} from '../../../windowManager'
import {
  GEO_LOCATION_UNSUPPORTED,
  ATTEMPTING_GPS,
  UTC_FORMAT,
  NO_CACHE,
  CHECK_OUT,
  FIXED,
} from '../../../globalConstants'
import ActionButton from '../../ActionButton/ActionButton'
import { setCurrentPosition } from '../../GeoLocation/actionCreator'
import {
  setRemedyErrorValidation,
  setVisitNotesErrorValidation,
  setCauseErrorValidation,
} from './actionCreator'

export const UPDATE_VISIT = gql`
  mutation UpdateVisit(
    $visit: VisitInput!
    $visitId: String!
    $woStatus: String
  ) {
    updateVisit(visit: $visit, visitId: $visitId, woStatus: $woStatus) {
      visitId
    }
  }
`

const FixedButton = ({
  followUpActive,
  minCommentLengthReached,
  cause,
  remedy,
  client,
  toggleRender,
  setMessage,
  history,
  shouldRender,
  setCurrentPosition,
  setRemedyErrorValidation,
  setVisitNotesErrorValidation,
  setCauseErrorValidation,
  selectedCause,
}) => {
  const checkOutVisit = async (lat, lon) => {
    let response = {
      data: {
        updateVisit: {},
      },
    }
    let message = null
    const searchParams = getURLSearchParams()
    const visitId = searchParams.get('visit_id')
    try {
      toggleRender(true)
      setMessage(`Attempting to ${CHECK_OUT} of visit #${visitId}`)
      const body = {
        check_out_time: moment().utc().format(UTC_FORMAT),
        visit_status: CHECK_OUT,
        check_out_latitude: lat,
        check_out_longitude: lon,
        reference_id: searchParams.get('work_order_number'),
      }
      response = await client.mutate({
        mutation: UPDATE_VISIT,
        variables: {
          visit: body,
          visitId: visitId,
          woStatus: searchParams.get('status'),
        },
        fetchPolicy: NO_CACHE,
      })
      response.data.updateVisit.visitId &&
        history.push(`/check-out-confirmation#?company_id=${getCompanyId()}`)
    } catch (e) {
      message = `Unable to ${CHECK_OUT} of visit #${visitId}`
    } finally {
      toggleRender(false)
      setMessage(message)
      setCurrentPosition(lat, lon)
    }
  }

  const success = (position) => {
    setMessage()
    checkOutVisit(position.coords.latitude, position.coords.longitude)
  }

  const error = (error) => {
    setMessage(error.message)
    checkOutVisit()
  }

  const notSupported = () => {
    setMessage(GEO_LOCATION_UNSUPPORTED)
    checkOutVisit()
  }

  const onClickFixedButton = () => {
    const canFix = minCommentLengthReached && remedy && cause && !shouldRender
    !minCommentLengthReached && setVisitNotesErrorValidation(true)

    !cause && setCauseErrorValidation(true)
    selectedCause && !remedy && setRemedyErrorValidation(true)

    if (canFix) {
      setMessage(ATTEMPTING_GPS)
      toggleRender(true)
      geoLocationInNavigator()
        ? navigator.geolocation.getCurrentPosition(success, error)
        : notSupported()
    }
  }

  return (
    !followUpActive && (
      <ActionButton
        text={FIXED}
        onClick={onClickFixedButton}
        icon={<AssignmentTurnedInIcon />}
      />
    )
  )
}

const mapStateToProps = (state) => {
  return {
    followUpActive: state.followUpReasonReducer.followUpActive,
    minCommentLengthReached: state.visitCommentReducer.minCommentLengthReached,
    cause: state.causeReducer.cause,
    remedy: state.remedyReducer.remedy,
    shouldRender: state.linearProgressReducer.shouldRender,
    selectedCause: state.causeReducer.cause,
  }
}

const mapDispatchToProps = {
  setMessage,
  toggleRender,
  setCurrentPosition,
  setCauseErrorValidation,
  setRemedyErrorValidation,
  setVisitNotesErrorValidation,
}

export default withApollo(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(FixedButton)),
)
