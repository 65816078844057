import { Grid } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import ActionButton from '../ActionButton/ActionButton'
import SearchIcon from '@material-ui/icons/Search'
import { withRouter } from 'react-router-dom'
import {
  SERVICE_TECH,
  INVALID_GPS_BODY,
  INVALID_GPS_TITLE,
} from '../../globalConstants'
import Check from '@material-ui/icons/Check'
import Avatar from '@material-ui/core/Avatar'
import { toggleRender } from '../MatLinearProgress/actionCreator'
import { connect } from 'react-redux'
import { setMessage } from '../UserFeedback/actionCreator'
import { getCompanyId } from '../../windowManager'
import InlineFeedback from '../InlineFeedback/InlineFeedback'
import WarningIcon from '@material-ui/icons/Warning'

const styles = (theme) => ({
  gridParent: {
    backgroundColor: theme.palette.primary.main,
    minHeight: '100vh',
    color: theme.palette.secondary.action,
    position: 'absolute',
    top: 0,
    left: 0,
  },
  textConfirmation: {
    margin: theme.spacing(2),
  },
  checkMarkIcon: {
    color: theme.palette.primary.action,
  },
  avatar: {
    height: theme.spacing(8),
    width: theme.spacing(8),
    backgroundColor: theme.palette.secondary.action,
  },
  gridCentered: {
    textAlign: 'center',
    width: '100vw',
  },
  gridItem: {
    textAlign: 'left',
    marginTop: theme.spacing(2),
  },
  feedbackWrapper: {
    // on large(r) devices, we limit the width of the feedback window
    [theme.breakpoints.up('sm')]: {
      maxWidth: '50%',
    },
  },
})

export const ConfirmationScreen = ({
  classes,
  history,
  toggleRender,
  setMessage,
}) => {
  toggleRender(false)
  setMessage()
  return (
    <>
      <Grid container className={classes.gridParent} alignItems={'center'}>
        <Grid item className={classes.gridCentered}>
          <Grid item>
            <Typography variant={'h6'} className={classes.textConfirmation}>
              {SERVICE_TECH}
            </Typography>
          </Grid>
          <Grid container justifyContent={'center'}>
            <Avatar className={classes.avatar}>
              <Check className={classes.checkMarkIcon} fontSize={'large'} />
            </Avatar>
          </Grid>
          <Grid item>
            <Typography className={classes.textConfirmation}>
              Successfully Checked Out.
            </Typography>
          </Grid>
          <Grid>
            <ActionButton
              text={'Return to Search'}
              onClick={() =>
                history.push(`/search#?company_id=${getCompanyId()}`)
              }
              icon={<SearchIcon />}
              variant={'contained'}
            />
          </Grid>
          <Grid container className={classes.gridItem} justifyContent="center">
            <Grid className={classes.feedbackWrapper}>
              <InlineFeedback
                icon={<WarningIcon />}
                title={INVALID_GPS_TITLE}
                body={INVALID_GPS_BODY}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

const mapDispatchToProps = { toggleRender, setMessage }

export default withStyles(styles)(
  connect(null, mapDispatchToProps)(withRouter(ConfirmationScreen)),
)
