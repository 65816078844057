import {
  SET_FOLLOW_UP_REASON,
  SET_FOLLOW_UP_ACTIVE,
  SET_ERROR_FOLLOW_UP_REASON,
} from './actionType'

export const initialState = {
  followUpReason: '',
  followUpActive: false,
  showFollowUpReasonErrorValidation: false,
}

export default function followUpReasonReducer(
  state = initialState,
  action = {},
) {
  switch (action.type) {
    case SET_FOLLOW_UP_REASON: {
      const newState = {
        ...state,
        followUpReason: action.followUpReason,
      }
      return newState
    }
    case SET_FOLLOW_UP_ACTIVE: {
      const newState = {
        ...state,
        followUpActive: action.followUpActive,
      }
      return newState
    }
    case SET_ERROR_FOLLOW_UP_REASON: {
      const newState = {
        ...state,
        showFollowUpReasonErrorValidation:
          action.showFollowUpReasonErrorValidation,
      }
      return newState
    }
    default:
      return state
  }
}
