import { Component } from 'react'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import { Tooltip } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import { setURLSearchParams } from '../../windowManager'
import DeleteProfileDialog from './DeleteProfileDialog'
import { DELETE_PROFILE } from '../../globalConstants'

const styles = (theme) => ({
  actionButton: {
    margin: theme.spacing(0, -1.5, 0, 0.5),
    color: theme.palette.secondary.action,
  },
})
class DeleteProfileButton extends Component {
  render = () => {
    const { classes } = this.props

    const onClickDelete = () => {
      setURLSearchParams([{ name: 'open', value: true }])
    }

    return (
      <>
        <Tooltip title={DELETE_PROFILE} aria-label={DELETE_PROFILE}>
          <IconButton
            aria-label={DELETE_PROFILE}
            className={classes.actionButton}
            onClick={onClickDelete}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
        <DeleteProfileDialog />
      </>
    )
  }
}

export default withStyles(styles)(withRouter(DeleteProfileButton))
