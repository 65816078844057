import CurrentProfile from '../CurrentProfile/CurrentProfile'
import CreateProfileButton from '../CreateProfile/CreateProfileButton'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'

const styles = (theme) => ({
  gridParent: {
    padding: theme.spacing(1),
    marginTop: theme.spacing(0.5),
  },
})

const Profiles = ({ classes }) => {
  return (
    <Grid container className={classes.gridParent} spacing={2}>
      <Grid item xs={12}>
        <CurrentProfile />
      </Grid>
      <Grid item container xs={12} justifyContent={'flex-end'}>
        <CreateProfileButton />
      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(Profiles)
