export const NO_RESULTS = 'Invalid work order number'
export const WO_IN_VREPAIR = 'This work order is already in REPAIRED status'
export const SEARCH_FOR_WORK_ORDER_NUMBER = 'Search for a work order number'
export const SEARCH_BY_STORE_NUMBER = 'Search by store number (ie: T0001)'
export const LINKS_MESSAGE =
  'Please use your company link or the direct work order link to get started.'
export const WORK_ORDER_NUMBER_DIGIT_LENGTH = 10
export const STORE_NUMBER_DIGIT_LENGTH = 5
export const LOADING_WORK_ORDER = 'We are loading your work order.'
export const LOADING_WORK_ORDERS = 'We are loading your work orders.'
export const GEO_LOCATION_UNSUPPORTED =
  'Your device does not support geolocation.'
export const ATTEMPTING_GPS = 'Attempting to get your GPS coordinates...'
export const DATE_MISSING = '(date missing)'
export const UTC_FORMAT = 'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'
export const LOCAL_FORMAT = 'MM/DD/YY • hh:mm a'
export const LOADING = 'Loading...'
export const FAILED_TO_FETCH = 'Failed to fetch'
export const NO_CACHE = 'no-cache'
export const FOLLOW_UP_REASON = 'Follow-Up Reason'
export const CHECK_IN = 'Check-in'
export const EN_ROUTE = 'En route'
export const VINPRG = 'VINPRG'
export const VACCEPT = 'VACCEPT'
export const VREPAIR = 'VREPAIR'
export const PAPPR = 'PAPPR'
export const PDEC = 'PDEC'
export const PREVIEW = 'PREVIEW'
export const CHECK_OUT = 'Check-out'
export const FOLLOW_UP = 'Follow-up'
export const WORKORDER = 'workorder details'
export const VISIT = 'visit'
export const SERVICE_TECH = 'ServiceTech'
export const CANCEL = 'cancel'
export const OK = 'OK'
export const FIXED = 'FIXED'
export const PROVIDE = 'Provide:'
export const WORK_ORDER_PHOTOS = 'Work Order Photos'
export const LOG_OUT = 'Log Out'
export const PHONE_NUMBER = 'Phone Number'
export const NAME = 'Name'
export const BACK = 'back'
export const CREATE_PROFILE = 'Create Profile'
export const PROFILES = 'profiles'
export const NAME_CHARACTERS_LENGTH = 255
export const LOCAL_STORAGE_UNSUPPORTED =
  'Your browser does not support the use of Local Storage.'
export const LOCAL_STORAGE_UNSUPPORTED_INSTRUCTIONS =
  'Please review your browser settings and make sure that sites are allowed to read and save Web Storage data.'
export const DEFAULT_TECH_ID = '000000'
export const EDIT_PROFILE = 'Edit Profile'
export const DELETE_PROFILE = 'Delete Profile'
export const MIN_NAME_LENGTH = 2
export const MIN_PHONENUMBER_LENGTH = 0
export const MAX_PHONENUMBER_LENGTH = 14
export const INVALID_GPS_TITLE = 'Unable to get GPS data from your device'
export const INVALID_GPS_BODY =
  'While your last check-in or check-out was successful, your device did not provide a set of valid GPS coordinates. Please check your browser and / or device settings to ensure GPS is enabled.'
export const SEARCH_OPTION = 'searchOption'
export const GETTING_COMPANY = 'Getting company info...'
export const NO_WORKORDERS_FOR_STORE = 'No active work orders for this store'
export const ERROR_WO_FOR_STORE = 'Unable to get work orders'
export const ARRIVE_BY = 'Arrive By:'
export const REPAIR_BY = 'Repair By:'
export const NEXT_STEPS = 'next steps'
export const UPLOAD_PHOTOS = 'Upload Photos'
