import { SET_SELECTED_DATE_TIME } from './actionType'

export const initialState = {
  selectedDateTime: null,
}

export default function selectedDateReducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_SELECTED_DATE_TIME: {
      const newState = {
        selectedDateTime: action.selectedDateTime,
      }
      return newState
    }
    default:
      return state
  }
}
