import { Component } from 'react'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { gql } from 'apollo-boost'
import { withApollo } from 'react-apollo'
import { getURLSearchParams } from '../../../windowManager'
import {
  LOADING,
  FAILED_TO_FETCH,
  NO_CACHE,
  PROVIDE,
} from '../../../globalConstants'
import { connect } from 'react-redux'
import { setCause } from './actionCreator'
import { setMessage } from '../../GeoLocation/actionCreator'
import { toggleRender } from '../../MatLinearProgress/actionCreator'
import { updateWorkOrderQuery } from '../UpdateWorkOrderQuery'
import FormHelperText from '@material-ui/core/FormHelperText'
import { setCauseErrorValidation } from '../../VisitDetail/FixedButton/actionCreator'

export const getCausesQuery = gql`
  query GetCauses($woNum: String, $problemCode: String) {
    getCauses(woNum: $woNum, problemCode: $problemCode) {
      failureCode
    }
  }
`

export const getCause = gql`
  query GetWorkOrder(
    $woNum: String!
    $companyId: String!
    $technicianId: String!
  ) {
    getWorkOrder(
      woNum: $woNum
      companyId: $companyId
      technicianId: $technicianId
    ) {
      cause
    }
  }
`

export class CauseDropdown extends Component {
  constructor() {
    super()
    this.state = {
      causes: [],
      displayMessage: LOADING,
      loadingCause: false,
    }
  }

  componentDidMount = async () => {
    const { client, toggleRender } = this.props
    const searchParams = getURLSearchParams()
    const workOrderNumber = searchParams.get('work_order_number')
    const problemCode = searchParams.get('problem_code')
    try {
      toggleRender(true)
      const causesData = await client.query({
        query: getCausesQuery,
        variables: { woNum: workOrderNumber, problemCode: problemCode },
        fetchPolicy: NO_CACHE,
      })
      const causes = causesData.data.getCauses
      if (causes.length) {
        this.setState({
          causes: causes.map((causesObject) => causesObject.failureCode),
        })
        this.getWorkOrderCause(workOrderNumber)
      } else {
        this.setState({
          displayMessage: 'No causes found',
        })
      }
    } catch (e) {
      this.setState({
        displayMessage: FAILED_TO_FETCH,
      })
    } finally {
      toggleRender(false)
    }
  }

  getWorkOrderCause = async (workOrderNumber) => /* istanbul ignore next */ {
    const { session, client, setCause, toggleRender } = this.props
    let response = {
      data: {
        getWorkOrder: [],
      },
    }
    try {
      toggleRender(true)
      this.setState({
        displayMessage: LOADING,
        loadingCause: true,
      })
      const searchParams = getURLSearchParams()
      response = await client.query({
        query: getCause,
        variables: {
          woNum: workOrderNumber,
          companyId: searchParams.get('company_id'),
          technicianId: session.userInfo.technicianId,
        },
        fetchPolicy: NO_CACHE,
      })
      setCause(response.data.getWorkOrder[0].cause || '')
    } catch (e) {
      this.setState({
        displayMessage: FAILED_TO_FETCH,
      })
    } finally {
      this.setState({
        loadingCause: false,
      })
      toggleRender(false)
    }
  }

  updateCause = async (cause) => {
    const {
      client,
      setMessage,
      toggleRender,
      setCause,
      setCauseErrorValidation,
    } = this.props
    const searchParams = getURLSearchParams()
    const workOrderNumber = searchParams.get('work_order_number')
    let updateResponse = {
      data: {
        updateWorkOrder: {},
      },
    }
    let message = null
    try {
      toggleRender(true)
      setMessage(`Updating cause for Work Order #${workOrderNumber}`)
      updateResponse = await client.mutate({
        mutation: updateWorkOrderQuery,
        variables: {
          workorder: { cause: cause },
          woNum: workOrderNumber,
        },
        fetchPolicy: NO_CACHE,
      })
    } catch (e) {
      message = `Unable to update cause for Work Order #${workOrderNumber}`
    } finally {
      toggleRender(false)
      if (updateResponse.data.updateWorkOrder.message) {
        setCause(cause)
        setMessage(
          `Successfully updated cause for Work Order #${workOrderNumber}`,
        )
        setCauseErrorValidation(false)
      } else {
        setMessage(message)
      }
    }
  }

  handleChange = (event) => {
    const { cause } = this.props
    const newCause = event.target.value
    if (newCause !== cause) {
      this.updateCause(newCause)
    }
  }

  render = () => {
    const causesLength = this.state.causes.length
    const showLoadingState = !causesLength || this.state.loadingCause
    const { cause, showCauseErrorValidation } = this.props
    const useProvideText = !cause ? PROVIDE : ''

    return (
      <FormControl fullWidth error={showCauseErrorValidation}>
        <InputLabel>
          {showLoadingState
            ? this.state.displayMessage
            : `${useProvideText} Cause`}
        </InputLabel>
        <Select
          value={cause || ''}
          onChange={this.handleChange}
          disabled={!causesLength}
        >
          <MenuItem value={''} />
          {this.state.causes.map((cause, index) => {
            return (
              <MenuItem value={cause} key={index}>
                {cause}
              </MenuItem>
            )
          })}
        </Select>
        {showCauseErrorValidation && (
          <FormHelperText>*Please choose a cause.</FormHelperText>
        )}
      </FormControl>
    )
  }
}

export const mapStateToProps = (state) => {
  return {
    cause: state.causeReducer.cause,
    session: state.authCredentialsReducer.auth.session,
    showCauseErrorValidation: state.fixedButtonReducer.showCauseErrorValidation,
  }
}

const mapDispatchToProps = {
  setCause,
  setMessage,
  toggleRender,
  setCauseErrorValidation,
}

export default withApollo(
  connect(mapStateToProps, mapDispatchToProps)(CauseDropdown),
)
