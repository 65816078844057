import { withStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import CompanyName from '../Company/CompanyName'
import ProfileInfo from '../ProfileInfo/ProfileInfo'
import { getCompanyId } from '../../windowManager'

const styles = (theme) => ({
  gridParent: {
    padding: theme.spacing(2),
    color: theme.palette.secondary.action,
    backgroundColor: theme.palette.primary.alternate,
    margin: theme.spacing(0.1, 0, 0.2, 0),
  },
  gridItemLeft: {
    paddingRight: theme.spacing(1),
  },
  gridItemRight: {
    paddingLeft: theme.spacing(1),
  },
})

const CompanyProfileHeader = ({ classes }) => {
  return (
    getCompanyId() && (
      <Grid container className={classes.gridParent}>
        <Grid item xs={6} className={classes.gridItemLeft}>
          <CompanyName />
        </Grid>
        <Grid
          item
          container
          xs={6}
          className={classes.gridItemRight}
          justifyContent={'flex-end'}
        >
          <ProfileInfo />
        </Grid>
      </Grid>
    )
  )
}

export default withStyles(styles)(CompanyProfileHeader)
