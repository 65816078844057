import { Component } from 'react'
import { connect } from 'react-redux'
import { setVisitComments } from './actionCreator'
import TextField from '@material-ui/core/TextField'
import { saveNotesQuery } from './SaveNotesQuery'
import { withApollo } from 'react-apollo'
import { getURLSearchParams } from '../../../windowManager'
import { gql } from 'apollo-boost'
import { toggleRender } from '../../MatLinearProgress/actionCreator'
import { setMessage } from '../../UserFeedback/actionCreator'
import { NO_CACHE } from '../../../globalConstants'
import { setVisitNotesErrorValidation } from '../../VisitDetail/FixedButton/actionCreator'

export const GET_VISIT_DETAIL = gql`
  query GetVisitDetails($visitId: String!) {
    getVisitDetails(visitId: $visitId) {
      visitComments
    }
  }
`

export class VisitComments extends Component {
  componentWillUnmount = async () => {
    // visitId must come from the parent as a prop,
    // it cannot be pulled from the URL during the unmount,
    // as during unmount it doesn't have access to the current url params
    const { visitComments, client, visitId } = this.props
    await client.query({
      query: saveNotesQuery,
      variables: {
        visitId: visitId,
        visit: { visit_comments: visitComments.trim() },
      },
      fetchPolicy: NO_CACHE,
    })
  }

  componentDidMount = async () => {
    const { client, toggleRender, setMessage, setVisitComments, visitId } =
      this.props
    const searchParams = getURLSearchParams()
    const workOrderNumber = searchParams.get('work_order_number')
    const location = searchParams.get('location')
    this.setState({
      visitId: visitId,
      workOrderNumber: workOrderNumber,
      location: location,
    })
    let message = null
    try {
      setVisitComments('')
      toggleRender(true)
      setMessage(`Retrieving information for Visit #${visitId}...`)
      const details = await client.query({
        query: GET_VISIT_DETAIL,
        variables: {
          visitId: visitId,
        },
        fetchPolicy: NO_CACHE,
      })
      setVisitComments(details.data.getVisitDetails.visitComments || '')
    } catch (e) {
      message = `Unable to retrieve notes for Visit #${visitId}`
    } finally {
      toggleRender(false)
      setMessage(message)
    }
  }

  componentDidUpdate = (prevProps) => {
    const { minCommentLengthReached, showVisitNoteErrorValidation } = this.props
    if (prevProps.minCommentLengthReached !== minCommentLengthReached) {
      if (minCommentLengthReached && showVisitNoteErrorValidation) {
        setVisitNotesErrorValidation(false)
      }
    }
  }

  render = () => {
    const {
      visitId,
      setVisitComments,
      visitComments,
      minCommentLengthReached,
      errorMessageShown,
      showVisitNoteErrorValidation,
    } = this.props
    const handleChange = () => (event) => {
      setVisitComments(event.target.value)
    }

    const visitErrorShown =
      showVisitNoteErrorValidation &&
      errorMessageShown &&
      !minCommentLengthReached
    return (
      <TextField
        value={visitComments || ''}
        error={visitErrorShown}
        helperText={visitErrorShown && '*Please enter visit comments'}
        onChange={handleChange()}
        multiline
        variant={'outlined'}
        minRows={6}
        fullWidth
        InputLabelProps={{
          shrink: true,
        }}
        label={`Visit comments for visit ID: ${visitId}`}
      />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    visitComments: state.visitCommentReducer.visitComments,
    showVisitNoteErrorValidation:
      state.fixedButtonReducer.showVisitNoteErrorValidation,
    minCommentLengthReached: state.visitCommentReducer.minCommentLengthReached,
  }
}

const mapDispatchToProps = {
  setVisitComments,
  toggleRender,
  setMessage,
  setVisitNotesErrorValidation,
}

export default withApollo(
  connect(mapStateToProps, mapDispatchToProps)(VisitComments),
)
