import {
  SET_AUTH_CREDENTIALS,
  SET_COMPANY_ID,
  SET_COMPANY_NAME,
  SET_PROFILES,
} from './actionType'

const initialState = {
  auth: {
    session: {
      userInfo: {
        name: '',
        phone: '',
        technicianId: '000000',
      },
    },
  },
  profiles: [],
}

export default function authCredentialsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_AUTH_CREDENTIALS: {
      const session = action.auth.session
      const userInfo = session.userInfo
      const newState = {
        ...state,
        auth: {
          session: {
            userInfo: {
              name: userInfo.name,
              phone: userInfo.phone,
              technicianId: userInfo.lanId,
            },
          },
        },
      }
      return newState
    }
    case SET_COMPANY_ID: {
      const newState = {
        ...state,
        companyId: action.companyId,
      }
      return newState
    }
    case SET_COMPANY_NAME: {
      const newState = {
        ...state,
        companyName: action.companyName,
      }
      return newState
    }
    case SET_PROFILES: {
      const newState = {
        ...state,
        profiles: action.profiles,
      }
      return newState
    }
    default:
      return state
  }
}
