/*
👋 “Hey” from the Praxis team
Praxis ships with support for modern browsers, but you may have users or external vendors that require IE11.
If that’s the case - uncomment the below imports.
Learn more about our the default browser support in the README.

import '@praxis/react-app-polyfill/ie11'
import '@praxis/react-app-polyfill/stable'
*/

import App from './components/App'
import * as serviceWorker from './serviceWorker'
import { Provider } from 'react-redux'
import configureStore from './store/configureStore'
import './index.css'
import 'typeface-roboto'
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import ApolloClient from 'apollo-boost'
import { ApolloProvider } from '@apollo/react-components'
import apiConfig from './config/apiConfig'
import { createRoot } from 'react-dom/client'

const theme = createTheme({
  palette: {
    primary: {
      main: '#333',
      alternate: '#666',
      action: '#366cd9',
    },
    secondary: {
      main: '#cc0000',
      alternate: '#333',
      action: '#fff',
    },
    background: {
      default: '#d6d6d6',
    },
  },
})

const store = configureStore()
const client = new ApolloClient({
  request: (operation) => {
    operation.setContext({
      headers: {
        'x-api-key': apiConfig.auth.apiKey,
      },
    })
  },
  uri: `${apiConfig.workorder.host}graphql`,
})

const container = document.getElementById('root')
const root = createRoot(container)
root.render(
  <MuiThemeProvider theme={theme}>
    <CssBaseline />
    <ApolloProvider client={client}>
      <Provider store={store}>
        <App />
      </Provider>
    </ApolloProvider>
  </MuiThemeProvider>,
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
