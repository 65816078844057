import {
  SET_INITIAL_PROFILE_NAME,
  SET_INITIAL_PROFILE_PHONE,
  SET_EDIT_PROFILE_NAME,
  SET_EDIT_PROFILE_PHONE,
  SET_EDIT_PROFILE_ID,
} from './actionType'

export function setInitialProfileName(profileName) {
  return {
    type: SET_INITIAL_PROFILE_NAME,
    profileName: profileName,
  }
}

export function setInitialProfilePhone(profilePhone) {
  return {
    type: SET_INITIAL_PROFILE_PHONE,
    profilePhone: profilePhone,
  }
}

export function setEditProfileName(profileName) {
  return {
    type: SET_EDIT_PROFILE_NAME,
    profileName: profileName,
  }
}

export function setEditProfilePhone(profilePhone) {
  return {
    type: SET_EDIT_PROFILE_PHONE,
    profilePhone: profilePhone,
  }
}

export function setEditProfileId(profileId) {
  return {
    type: SET_EDIT_PROFILE_ID,
    profileId: profileId,
  }
}
