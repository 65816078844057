import Paper from '@material-ui/core/Paper'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import { withStyles } from '@material-ui/core/styles'
import {
  SEARCH_FOR_WORK_ORDER_NUMBER,
  SEARCH_BY_STORE_NUMBER,
} from '../../globalConstants'
import { connect } from 'react-redux'
import { setSearchOption } from '../Search/actionCreator'
import { getURLSearchParams } from '../../windowManager'

const styles = (theme) => ({
  formControlLabel: {
    width: '100%',
  },
  paper: {
    margin: theme.spacing(2, 1.5, 0, 1.5),
    padding: theme.spacing(1, 1, 1, 2),
  },
})

const CompanySeachOptions = ({ classes, setSearchOption, searchOption }) => {
  const onChange = (event) => {
    setSearchOption(event.target.value)
  }

  const urlParams = getURLSearchParams()

  if (urlParams.has('wonum')) {
    setSearchOption('woNum')
  } else if (urlParams.has('store_id')) {
    setSearchOption('storeId')
  }

  return (
    <RadioGroup
      defaultValue={searchOption}
      aria-label="search options"
      onChange={onChange}
    >
      <FormControl>
        <Paper className={classes.paper}>
          <FormControlLabel
            className={classes.formControlLabel}
            value="woNum"
            control={<Radio color={'default'} />}
            label={SEARCH_FOR_WORK_ORDER_NUMBER}
          />
          {/* Commenting out "Search by Store Number" option, including the related import, until this functionality is developed */}
        </Paper>
        <Paper className={classes.paper}>
          <FormControlLabel
            className={classes.formControlLabel}
            value="storeId"
            control={<Radio color={'default'} />}
            label={SEARCH_BY_STORE_NUMBER}
          />
        </Paper>
      </FormControl>
    </RadioGroup>
  )
}

const mapStateToProps = (state) => {
  return {
    searchOption: state.searchedWorkOrdersReducer.searchOption,
  }
}

const mapDispatchToProps = {
  setSearchOption,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(CompanySeachOptions))
