import { isLocal } from '../windowManager'

const serviceTechUrl = isLocal()
  ? 'http://localhost:8080/service_technician_data/v1/'
  : 'https://servicetechapi-dev.dev.target.com/service_technician_data/v1/'

const envConfigs = {
  dev: {
    auth: {
      clientId: 'servicetech_npe_im',
      apiKey: 'd15b7784ebcfc24c265d55239da48ea8f627c132',
    },
    workorder: {
      host: serviceTechUrl,
    },
  },
  stg: {
    auth: {
      clientId: 'servicetech_npe_im',
      apiKey: 'd15b7784ebcfc24c265d55239da48ea8f627c132',
    },
    workorder: {
      host: 'https://stage-api.target.com/service_technician_data/v1/',
    },
  },
  prod: {
    auth: {
      clientId: 'servicetech_prod_im',
      apiKey: '09fd862fcb53f6ba04d1c31c196039e1aab2e363',
    },
    workorder: {
      host: 'https://api.target.com/service_technician_data/v1/',
    },
  },
}

const appEnv = process.env.REACT_APP_ENV
const config = envConfigs[appEnv]
const apiConfig = config

export default apiConfig
