import Grid from '@material-ui/core/Grid'
import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import moment from 'moment'
import CardContent from '@material-ui/core/CardContent'
import {
  DATE_MISSING,
  LOCAL_FORMAT,
  ARRIVE_BY,
  REPAIR_BY,
} from '../../globalConstants'
import UtcTimeToLocal from '../ConvertUtcTimeToLocal/UtcTimeToLocal'

const styles = (theme) => ({
  gridItemValue: {
    textAlign: 'right',
    flexGrow: 1,
    color: theme.palette.primary.alternate,
  },
})

const MatCardContent = ({ workOrder, classes }) => {
  const timeBy = (text, time) => {
    return (
      <Grid container>
        <Grid item>
          <Typography>{text}</Typography>
        </Grid>
        <Grid item className={classes.gridItemValue}>
          <Typography>
            {time ? moment(time).local().format(LOCAL_FORMAT) : DATE_MISSING}
          </Typography>
        </Grid>
      </Grid>
    )
  }
  const firstVisit = workOrder.visits[0]

  return (
    <CardContent>
      <Grid container>
        <Grid item>
          <Typography>Work Order Number:</Typography>
        </Grid>
        <Grid item className={classes.gridItemValue}>
          <Typography>{workOrder.workOrderNumber}</Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item>
          <Typography>Site ID:</Typography>
        </Grid>
        <Grid item className={classes.gridItemValue}>
          <Typography>{workOrder.locationId}</Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item>
          <Typography>Asset:</Typography>
        </Grid>
        <Grid item className={classes.gridItemValue}>
          <Typography>{workOrder.assetName}</Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item>
          <Typography>Problem Code:</Typography>
        </Grid>
        <Grid item className={classes.gridItemValue}>
          <Typography>{workOrder.problemCode}</Typography>
        </Grid>
      </Grid>
      {timeBy(ARRIVE_BY, workOrder.expectedResponseTime)}
      {timeBy(REPAIR_BY, workOrder.expectedRepairTime)}
      <Grid container justifyContent="center">
        {workOrder.visits.length && firstVisit.checkInTime ? (
          <UtcTimeToLocal date={firstVisit.visitExpiration}></UtcTimeToLocal>
        ) : null}
      </Grid>
    </CardContent>
  )
}

export default withStyles(styles)(MatCardContent)
