import ActionButton from '../ActionButton/ActionButton'
import SaveIcon from '@material-ui/icons/Save'
import { connect } from 'react-redux'
import { setMessage } from '../GeoLocation/actionCreator'
import {
  setProfiles,
  setAuthCredentials,
} from '../../common/UserAuth/actionCreator'
import { PROFILES } from '../../globalConstants'
import { getLocalStorage } from '../../windowManager'

const UPDATE_PROFILE = 'update profile'

const UpdateProfileButton = ({
  minNameLengthReached,
  profileName,
  editedProfileName,
  profilePhone,
  editedProfilePhone,
  profileId,
  profiles,
  setProfiles,
  setAuthCredentials,
  setMessage,
  maxPhoneNumberLengthReached,
}) => {
  const editedNameOrNumber =
    profileName === editedProfileName && profilePhone === editedProfilePhone

  const onClickUpdateProfileButton = () => {
    const currentProfiles = JSON.parse(JSON.stringify(profiles)) || []
    const updatedProfiles = currentProfiles.map((profile) =>
      profile.id === profileId
        ? { ...profile, name: editedProfileName, phone: editedProfilePhone }
        : profile,
    )
    const storage = getLocalStorage()
    if (storage) {
      storage.setItem(PROFILES, JSON.stringify(updatedProfiles))
      setMessage('Your profile has been Updated')
      setProfiles(updatedProfiles)
      const activeProfile = {
        session: {
          userInfo: {
            lanId: profileId,
            name: editedProfileName,
            phone: editedProfilePhone,
          },
        },
      }
      setAuthCredentials(activeProfile)
      window.history.back()
    } else {
      setMessage('Unable to edit profile due to Local Storage setting')
    }
  }

  return (
    <ActionButton
      text={UPDATE_PROFILE}
      onClick={onClickUpdateProfileButton}
      icon={<SaveIcon />}
      disabled={
        editedNameOrNumber ||
        !minNameLengthReached ||
        !maxPhoneNumberLengthReached
      }
    />
  )
}

const mapStateToProps = (state) => {
  return {
    profileName: state.editProfileReducer.profileName,
    profilePhone: state.editProfileReducer.profilePhone,
    profileId: state.editProfileReducer.profileId,
    editedProfileName: state.editProfileReducer.editedProfileName,
    editedProfilePhone: state.editProfileReducer.editedProfilePhone,
    minNameLengthReached: state.editProfileReducer.minNameLengthReached,
    maxPhoneNumberLengthReached:
      state.editProfileReducer.maxPhoneNumberLengthReached,
    profiles: state.authCredentialsReducer.profiles,
  }
}

const mapDispatchToProps = {
  setMessage,
  setProfiles,
  setAuthCredentials,
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateProfileButton)
