import apiConfig from '../../config/apiConfig'

const apiKey = `${apiConfig.auth.apiKey}`
const baseUrl = `${apiConfig.workorder.host}`
const httprequest = require('axios')

const getConfig = () => {
  return {
    headers: {},
    crossDomain: true,
  }
}

// url is the complete url with the all the parameters
export const fetchData = async (url) => {
  const finalUrl = `${baseUrl}${url}&key=${apiKey}`
  const response = await httprequest.get(finalUrl, getConfig())
  return response.data
}

export const postData = async (url, body) => {
  const finalUrl = `${baseUrl}${url}?key=${apiKey}`
  const response = await httprequest.post(finalUrl, body, getConfig())
  return response.data
}

export const putData = async (url, body) => {
  const finalUrl = `${baseUrl}${url}?key=${apiKey}`
  const response = await httprequest.put(finalUrl, body, getConfig())
  return response.data
}
