import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import {
  LOCAL_STORAGE_UNSUPPORTED,
  LOCAL_STORAGE_UNSUPPORTED_INSTRUCTIONS,
} from '../../globalConstants'
import { Typography } from '@material-ui/core'

const styles = (theme) => ({
  gridParent: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.secondary.action,
  },
  gridItem: {
    paddingTop: theme.spacing(2),
  },
})

const LocalStorageDisabledMessage = ({ classes }) => {
  return (
    <Grid container className={classes.gridParent}>
      <Grid item>
        <Typography variant={'h6'}>{LOCAL_STORAGE_UNSUPPORTED}</Typography>
      </Grid>
      <Grid item className={classes.gridItem}>
        <Typography variant={'body1'}>
          {LOCAL_STORAGE_UNSUPPORTED_INSTRUCTIONS}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(LocalStorageDisabledMessage)
