import ActionButton from '../ActionButton/ActionButton'
import SaveIcon from '@material-ui/icons/Save'
import { setProfileSaved } from './actionCreator'
import { connect } from 'react-redux'
import { setMessage } from '../GeoLocation/actionCreator'
import {
  setProfiles,
  setAuthCredentials,
} from '../../common/UserAuth/actionCreator'
import { PROFILES } from '../../globalConstants'
import { getLocalStorage } from '../../windowManager'

const SAVE_PROFILE = 'save profile'

const SaveProfileButton = ({
  minNameLengthReached,
  profileName,
  profilePhone,
  setMessage,
  profiles,
  setProfiles,
  saved,
  setProfileSaved,
  setAuthCredentials,
  maxPhoneNumberLengthReached,
}) => {
  const generateUniqueId = () => {
    // unique id consists of time stamp in seconds (10 digits)
    // + 10 digit random number
    const timeStamp = Math.floor(Date.now() / 1000).toString()
    const randomNumber = Math.floor(
      Math.pow(10, 9) +
        Math.random() * (Math.pow(10, 10) - Math.pow(10, 9) - 1),
    ).toString()
    return timeStamp + randomNumber
  }

  const profilesContains = (currentProfiles, profile) => {
    return currentProfiles.some(
      (arrayElement) =>
        arrayElement.name.toLowerCase() === profile.name.toLowerCase() &&
        arrayElement.phone === profile.phone,
    )
  }

  const onClickSaveProfileButton = () => {
    const profile = {
      id: generateUniqueId(),
      name: profileName,
      phone: profilePhone,
      active: true,
    }

    // This safely copies the profiles array in redux to avoid state mutation and sets all profiles active flag to false
    const currentProfiles =
      JSON.parse(JSON.stringify(profiles)).map((profile) => ({
        ...profile,
        active: false,
      })) || []

    if (profilesContains(currentProfiles, profile)) {
      setMessage('This profile already exists in this device')
    } else {
      currentProfiles.unshift(profile)
      const storage = getLocalStorage()
      storage && storage.setItem(PROFILES, JSON.stringify(currentProfiles))
      setProfiles(currentProfiles)

      const activeProfile = {
        session: {
          userInfo: {
            lanId: profile.id,
            name: profile.name,
            phone: profile.phone,
          },
        },
      }
      setAuthCredentials(activeProfile)
      window.history.back()
    }
    setProfileSaved(true)
  }
  return (
    <ActionButton
      text={SAVE_PROFILE}
      onClick={onClickSaveProfileButton}
      icon={<SaveIcon />}
      disabled={!minNameLengthReached || !maxPhoneNumberLengthReached || saved}
    />
  )
}

const mapStateToProps = (state) => {
  return {
    profileName: state.createProfileReducer.profileName,
    profilePhone: state.createProfileReducer.profilePhone,
    minNameLengthReached: state.createProfileReducer.minNameLengthReached,
    maxPhoneNumberLengthReached:
      state.createProfileReducer.maxPhoneNumberLengthReached,
    saved: state.createProfileReducer.saved,
    profiles: state.authCredentialsReducer.profiles,
  }
}

const mapDispatchToProps = {
  setProfileSaved,
  setMessage,
  setProfiles,
  setAuthCredentials,
}

export default connect(mapStateToProps, mapDispatchToProps)(SaveProfileButton)
