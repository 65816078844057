import Header from '../Header/Header'
import { getURLSearchParams } from '../../windowManager'
import { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import GpsHelp from './GpsHelp/GpsHelp'
const styles = (theme) => ({
  gridItem: {
    margin: theme.spacing(1),
    padding: theme.spacing(2),
    backgroundColor: theme.palette.secondary.action,
    borderColor: theme.palette.primary.alternate,
    borderWidth: 1,
    borderStyle: 'solid',
  },
})

const MAX_APP_BAR_HEIGHT = 64

class Faq extends Component {
  componentDidMount = () => {
    // react-router doesn't support anchor fragments, so we will use our own implementation
    const fragment = getURLSearchParams().get('fragment')
    const element = document.getElementById(fragment)
    element && document.getElementById(fragment).scrollIntoView()
    window.scrollBy(0, -MAX_APP_BAR_HEIGHT)
  }

  render = () => {
    const { classes } = this.props

    return (
      <>
        <Header arrowBack title={'F.A.Q.'} />
        <Grid container>
          <Grid item className={classes.gridItem} xs={12} id="gps">
            <GpsHelp />
          </Grid>
        </Grid>
      </>
    )
  }
}

export default withRouter(withStyles(styles)(Faq))
