import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn'
import { connect } from 'react-redux'
import {
  setFollowUpActive,
  setFollowUpReason,
  setFollowUpReasonError,
} from './FollowUpReasonDropdown/actionCreator'
import ActionButton from '../ActionButton/ActionButton'

const CancelButton = ({
  setFollowUpActive,
  followUpActive,
  setFollowUpReason,
  setFollowUpReasonError,
}) => {
  const onClick = () => {
    setFollowUpReason('')
    setFollowUpActive(false)
    setFollowUpReasonError(false)
  }
  return (
    followUpActive && (
      <ActionButton
        text={'back'}
        onClick={onClick}
        icon={<KeyboardReturnIcon />}
      />
    )
  )
}

const mapStateToProps = (state) => {
  return {
    followUpActive: state.followUpReasonReducer.followUpActive,
  }
}

const mapDispatchToProps = {
  setFollowUpActive,
  setFollowUpReason,
  setFollowUpReasonError,
}

export default connect(mapStateToProps, mapDispatchToProps)(CancelButton)
