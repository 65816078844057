import Button from '@material-ui/core/Button'
import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'

const styles = (theme) => ({
  actionButton: {
    color: theme.palette.primary.action,
  },
  actionText: {
    marginLeft: theme.spacing(1),
    width: '100%',
  },
  actionButtonDark: {
    color: theme.palette.secondary.action,
  },
})

const ActionButton = ({
  text,
  onClick,
  icon,
  disabled = false,
  variant = 'text',
  classes,
  color = 'inherit',
  fullWidth = false,
  shouldRender,
}) => {
  return (
    <Button
      aria-label={text}
      className={
        color === 'inherit' ? classes.actionButton : classes.actionButtonDark
      }
      onClick={onClick}
      disabled={shouldRender || disabled}
      variant={variant}
      color={color}
      fullWidth={fullWidth}
    >
      {icon}
      <Typography
        align={'left'}
        noWrap
        variant={'button'}
        className={classes.actionText}
      >
        {text}
      </Typography>
    </Button>
  )
}

const mapStateToProps = (state) => {
  return {
    shouldRender: state.linearProgressReducer.shouldRender,
  }
}

export default withStyles(styles)(connect(mapStateToProps, null)(ActionButton))
