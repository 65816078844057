import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { Snackbar } from '@material-ui/core'
import { setMessage } from './actionCreator'

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    padding: theme.spacing(2),
  },
  snackBar: {
    marginTop: theme.spacing(7),
    opacity: 0.9,
  },
})

const UserFeedback = ({ classes, message, open, setMessage }) => {
  const onCloseSnackBar = () => {
    setMessage()
  }
  return (
    <Snackbar
      className={classes.snackBar}
      open={open}
      message={<Typography>{message}</Typography>}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      onClose={onCloseSnackBar}
    />
  )
}

const mapStateToProps = (state) => {
  return {
    message: state.userFeedbackReducer.message,
    open: state.userFeedbackReducer.open,
  }
}

const mapDispatchToProps = {
  setMessage,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(UserFeedback))
