import { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import AppHeader from '../Header/Header'
import Grid from '@material-ui/core/Grid'
import { Typography } from '@material-ui/core'
import NameInput from '../CreateProfile/NameInput'
import PhoneNumberInput from '../CreateProfile/PhoneNumberInput'
import {
  setInitialProfileName,
  setInitialProfilePhone,
  setEditProfileId,
} from './actionCreator'
import { EDIT_PROFILE, PROFILES } from '../../globalConstants'
import { getURLSearchParams, getLocalStorage } from '../../windowManager'
import LocalStorageDisabledMessage from '../LocalStorageDisabledMessage/LocalStorageDisabledMessage'
import UpdateProfileButton from './UpdateProfileButton'

const styles = (theme) => ({
  gridParent: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.secondary.action,
  },
  gridItem: {
    paddingTop: theme.spacing(2),
  },
  // larger layout only stuff
  firstInput: {
    [theme.breakpoints.up('sm')]: {
      paddingRight: theme.spacing(4),
    },
  },
})

export class EditProfile extends Component {
  constructor() {
    super()
    this.state = {
      canUseLocalStorage: true,
    }
  }

  componentDidMount = () => {
    const { setInitialProfileName, setInitialProfilePhone, setEditProfileId } =
      this.props

    const storage = getLocalStorage()
    this.setState({ canUseLocalStorage: storage })
    if (storage) {
      const localStorageProfiles = JSON.parse(storage.getItem(PROFILES)) || []
      const profileId = getURLSearchParams().get('profile_id')
      const profileBeingEdited = localStorageProfiles.find(
        (profile) => profile.id === profileId,
      )
      if (profileBeingEdited) {
        setInitialProfileName(profileBeingEdited.name)
        setInitialProfilePhone(profileBeingEdited.phone)
        setEditProfileId(profileBeingEdited.id)
      }
    }
  }

  getCreateProfileForm = () => {
    const { classes } = this.props
    return (
      <Grid container className={classes.gridParent}>
        <Grid item xs={12}>
          <Typography variant={'h6'}>{EDIT_PROFILE}:</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          className={`${classes.gridItem} ${classes.firstInput}`}
        >
          <NameInput editProfile />
        </Grid>
        <Grid item xs={12} sm={6} className={classes.gridItem}>
          <PhoneNumberInput editProfile />
        </Grid>
        <Grid
          item
          container
          xs={12}
          justifyContent={'flex-end'}
          className={classes.gridItem}
        ></Grid>
        <Grid
          item
          container
          xs={12}
          justifyContent={'flex-end'}
          className={classes.gridItem}
        >
          <UpdateProfileButton />
        </Grid>
      </Grid>
    )
  }

  render = () => {
    const localStorageAvailable = this.state.canUseLocalStorage
    return (
      <>
        <AppHeader
          arrowBack
          title={EDIT_PROFILE}
          deleteProfileButton={localStorageAvailable}
        />
        {localStorageAvailable ? (
          this.getCreateProfileForm()
        ) : (
          <LocalStorageDisabledMessage />
        )}
      </>
    )
  }
}

const mapDispatchToProps = {
  setInitialProfileName,
  setInitialProfilePhone,
  setEditProfileId,
}

export default withStyles(styles)(
  connect(null, mapDispatchToProps)(EditProfile),
)
