import { Component } from 'react'
import BeenhereIcon from '@material-ui/icons/Beenhere'
import { gql } from 'apollo-boost'
import moment from 'moment'
import { setMessage } from '../GeoLocation/actionCreator'
import { toggleRender } from '../MatLinearProgress/actionCreator'
import { connect } from 'react-redux'
import { withApollo } from 'react-apollo'
import { withRouter } from 'react-router-dom'
import {
  geoLocationInNavigator,
  getURLSearchParams,
  getCompanyId,
} from '../../windowManager'
import {
  GEO_LOCATION_UNSUPPORTED,
  ATTEMPTING_GPS,
  UTC_FORMAT,
  NO_CACHE,
  CHECK_OUT,
} from '../../globalConstants'
import ActionButton from '../ActionButton/ActionButton'
import {
  setFollowUpActive,
  setFollowUpReason,
} from './FollowUpReasonDropdown/actionCreator'
import { setCurrentPosition } from '../GeoLocation/actionCreator'

export const GET_MULTIPLE_TECHS = gql`
  query GetVisitDetails($visitId: String!) {
    getVisitDetails(visitId: $visitId) {
      multipleTechs
    }
  }
`

export const UPDATE_VISIT = gql`
  mutation UpdateVisit($visit: VisitInput!, $visitId: String!) {
    updateVisit(visit: $visit, visitId: $visitId) {
      visitId
    }
  }
`

export class CheckOutButton extends Component {
  constructor() {
    super()
    this.state = {
      multipleTechs: false,
    }
  }

  componentDidMount = async () => {
    const { client } = this.props
    const searchParams = getURLSearchParams()
    const visitId = searchParams.get('visit_id')
    const details = await client.query({
      query: GET_MULTIPLE_TECHS,
      variables: {
        visitId: visitId,
      },
      fetchPolicy: NO_CACHE,
    })
    /* istanbul ignore next */ this.setState({
      multipleTechs: details.data.getVisitDetails.multipleTechs,
    })
  }

  render = () => {
    const { client, setMessage, toggleRender, history, setCurrentPosition } =
      this.props

    const checkOut = async (lat = 0.0, lon = 0.0) => {
      let response = {
        data: {
          updateVisit: {},
        },
      }
      let message = null
      const searchParams = getURLSearchParams()
      const visitId = searchParams.get('visit_id')
      try {
        setMessage(`Attempting to ${CHECK_OUT} of visit #${visitId}`)
        const body = {
          check_out_time: moment().utc().format(UTC_FORMAT),
          visit_status: CHECK_OUT,
          check_out_latitude: lat,
          check_out_longitude: lon,
        }
        response = await client.mutate({
          mutation: UPDATE_VISIT,
          variables: {
            visit: body,
            visitId: visitId,
          },
          fetchPolicy: NO_CACHE,
        })
      } catch (e) {
        message = `Unable to ${CHECK_OUT} of visit #${visitId}`
      } finally {
        toggleRender(false)
        if (response.data.updateVisit.visitId) {
          message = null
          history.push(`/check-out-confirmation#?company_id=${getCompanyId()}`)
        }
        setMessage(message)
        setCurrentPosition(lat, lon)
      }
    }

    const success = (position) => {
      setMessage()
      checkOut(position.coords.latitude, position.coords.longitude)
    }

    const error = (error) => {
      setMessage(error.message)
      checkOut()
    }

    const notSupported = () => {
      setMessage(GEO_LOCATION_UNSUPPORTED)
      checkOut()
    }

    const onClickCheckOutButton = () => {
      setMessage(ATTEMPTING_GPS)
      toggleRender(true)
      geoLocationInNavigator()
        ? navigator.geolocation.getCurrentPosition(success, error)
        : notSupported()
    }

    return (
      this.state.multipleTechs && (
        <ActionButton
          text={`${CHECK_OUT} as helper tech`}
          onClick={onClickCheckOutButton}
          icon={<BeenhereIcon />}
        />
      )
    )
  }
}

const mapStateToProps = (state) => {
  return {
    followUpReason: state.followUpReasonReducer.followUpReason,
    followUpActive: state.followUpReasonReducer.followUpActive,
  }
}

const mapDispatchToProps = {
  setMessage,
  toggleRender,
  setFollowUpReason,
  setFollowUpActive,
  setCurrentPosition,
}

export default withApollo(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(CheckOutButton)),
)
