import LinearProgress from '@material-ui/core/LinearProgress'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'

const styles = () => ({
  linearProgress: {
    position: 'fixed',
    left: '0px',
    right: '0px',
    top: '0px',
    zIndex: 10000,
  },
})

const MatLinearProgress = ({ classes, shouldRender }) => {
  return (
    shouldRender && (
      <LinearProgress className={classes.linearProgress} color={'secondary'} />
    )
  )
}

const mapStateToProps = (state) => {
  return {
    shouldRender: state.linearProgressReducer.shouldRender,
  }
}

export default connect(
  mapStateToProps,
  null,
)(withStyles(styles)(MatLinearProgress))
