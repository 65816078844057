import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { Typography } from '@material-ui/core'
import ActionButton from '../ActionButton/ActionButton'
import HelpIcon from '@material-ui/icons/Help'
import { withRouter } from 'react-router-dom'
import { getCompanyId } from '../../windowManager'

const styles = (theme) => ({
  gridItem: {
    padding: theme.spacing(2),
    borderStyle: 'dashed',
    borderColor: theme.palette.secondary.main,
    borderWidth: 2,
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.action,
  },
  iconWrapper: {
    bottom: -theme.spacing(0.5),
    marginRight: theme.spacing(1),
    position: 'relative',
    color: theme.palette.secondary.main,
  },
  body: {
    marginTop: theme.spacing(1),
  },
})

export const InlineFeedback = ({
  currentPosition,
  icon,
  title,
  body,
  classes,
  history,
}) => {
  const zeroGPS = 0.0
  return (
    currentPosition.latitude === zeroGPS &&
    currentPosition.longitude === zeroGPS && (
      <Grid container>
        <Grid item className={classes.gridItem}>
          {/* wrapping the icon in a span so it can both be styled and passed in dynamically */}
          <span className={classes.iconWrapper}>{icon}</span>
          <Typography variant="h6" display="inline" color="secondary">
            {title}
          </Typography>
          <Typography color="primary" className={classes.body} paragraph>
            {body}
          </Typography>
          <ActionButton
            text="get help with gps"
            icon={<HelpIcon />}
            onClick={() =>
              history.push(`/faq#?company_id=${getCompanyId()}&fragment=gps`)
            }
          />
        </Grid>
      </Grid>
    )
  )
}

const mapStateToProps = (state) => {
  return {
    currentPosition: state.geoLocationReducer.currentPosition,
  }
}

export default withRouter(
  connect(mapStateToProps, null)(withStyles(styles)(InlineFeedback)),
)
