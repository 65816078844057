import RadioGroup from '@material-ui/core/RadioGroup'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import { Grid, Typography } from '@material-ui/core'
import ClickablePaper from '../ClickablePaper/ClickablePaper'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import {
  setProfiles,
  setAuthCredentials,
} from '../../common/UserAuth/actionCreator'
import { PROFILES } from '../../globalConstants'
import { getLocalStorage } from '../../windowManager'

const useStyles = (theme) => ({
  radioGroup: {
    marginTop: theme.spacing(1),
  },
  formLabel: {
    color: theme.palette.primary.main,
  },
})

const CurrentProfile = ({
  classes,
  profiles,
  userInfo,
  setProfiles,
  setAuthCredentials,
}) => {
  const handleChange = (event) => {
    const currentProfiles =
      JSON.parse(JSON.stringify(profiles)).map((profile) => ({
        ...profile,
        active: false,
      })) || []

    const activeProfile = currentProfiles.find(
      (profile) => profile.id === event.target.value,
    )
    activeProfile.active = true

    const storage = getLocalStorage()
    storage && storage.setItem(PROFILES, JSON.stringify(currentProfiles))

    const sessionProfile = {
      session: {
        userInfo: {
          lanId: activeProfile.id,
          name: activeProfile.name,
          phone: activeProfile.phone,
        },
      },
    }
    setProfiles(currentProfiles)
    setAuthCredentials(sessionProfile)
  }

  return profiles.length ? (
    <FormControl component="fieldset" fullWidth>
      <FormLabel component="legend" className={classes.formLabel}>
        Profiles on Device:
      </FormLabel>
      <RadioGroup
        aria-label="tech"
        name="tech"
        value={userInfo.technicianId}
        onChange={handleChange}
        className={classes.radioGroup}
      >
        <Grid container spacing={2}>
          {profiles.map((profile, key) => (
            <Grid item xs={12} sm={6} key={key}>
              <ClickablePaper value={profile.id} text={profile.name} />
            </Grid>
          ))}
        </Grid>
      </RadioGroup>
    </FormControl>
  ) : (
    <Typography className={classes.formLabel}>
      No Profiles available on this device please create a profile
    </Typography>
  )
}

const mapStateToProps = (state) => {
  return {
    profiles: state.authCredentialsReducer.profiles,
    userInfo: state.authCredentialsReducer.auth.session.userInfo,
  }
}

const mapDispatchToProps = {
  setProfiles,
  setAuthCredentials,
}

export default withStyles(useStyles)(
  connect(mapStateToProps, mapDispatchToProps)(CurrentProfile),
)
