import { setMessage } from '../UserFeedback/actionCreator'
import { toggleRender } from '../MatLinearProgress/actionCreator'
import PersonIcon from '@material-ui/icons/Person'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withApollo } from 'react-apollo'
import { gql } from 'apollo-boost'
import { NO_CACHE, DEFAULT_TECH_ID } from '../../globalConstants'
import ActionButton from '../ActionButton/ActionButton'
import { dispatchHashChange } from '../../windowManager'

export const CLAIM_WORK_ORDER = gql`
  mutation CreateVisit($visit: VisitInput!, $woStatus: String) {
    createVisit(visit: $visit, woStatus: $woStatus) {
      visitId
    }
  }
`

const ClaimButton = ({
  workOrder,
  session,
  setMessage,
  toggleRender,
  client,
  companyId,
}) => {
  const onClickClaim = async () => {
    let response = {
      data: {
        createVisit: {},
      },
    }
    let message = null
    const workOrderNumber = workOrder.workOrderNumber
    const siteId = workOrder.locationId && workOrder.locationId.substring(1)
    const userInfo = session.userInfo
    const technicianName = userInfo.name
    const technicianId = userInfo.technicianId
    const technicianPhone = userInfo.phone

    try {
      toggleRender(true)
      setMessage(`Attempting to claim work order #${workOrderNumber}`)
      const body = {
        source_id: 1,
        parent_id: 0,
        technician_name: technicianName,
        location_id: siteId,
        reference_id: workOrderNumber,
        visit_status: 'Assigned',
        visitor_company_name: workOrder.companyName,
        visitor_company_id: companyId,
        technician_id: technicianId,
        visitor_phone: technicianPhone,
      }
      response = await client.mutate({
        mutation: CLAIM_WORK_ORDER,
        variables: {
          visit: body,
          woStatus: workOrder.status,
        },
        fetchPolicy: NO_CACHE,
      })
    } catch (e) {
      message = `Unable to claim work order #${workOrderNumber}`
    } finally {
      toggleRender(false)
      response.data.createVisit.visitId
        ? dispatchHashChange()
        : setMessage(message)
    }
  }
  return (
    session.userInfo.technicianId !== DEFAULT_TECH_ID && (
      <ActionButton
        text={`claim as ${session.userInfo.name}`}
        fullWidth
        onClick={onClickClaim}
        icon={<PersonIcon />}
      />
    )
  )
}

const mapDispatchToProps = {
  setMessage,
  toggleRender,
}

const mapStateToProps = (state) => {
  return {
    session: state.authCredentialsReducer.auth.session,
    companyId: state.authCredentialsReducer.companyId,
  }
}

export default withApollo(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(ClaimButton)),
)
