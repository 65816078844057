export const getURLSearchParams = () => {
  return new URLSearchParams(window.location.hash.split('#')[1])
}

export const geoLocationInNavigator = () => {
  return 'geolocation' in navigator
}

export const isLocal = () => {
  return window.location.href.includes('localhost')
}

const setLocation = (searchParams) => {
  const baseURL = window.location.href.split('#')[0]
  window.location.href = `${baseURL}#?${searchParams.toString()}`
}

export const setURLSearchParams = (urlSearchParams = []) => {
  const searchParams = getURLSearchParams()
  for (var i = 0; i < urlSearchParams.length; i++) {
    const urlSearchParam = urlSearchParams[i]
    searchParams.set(urlSearchParam.name, urlSearchParam.value)
  }
  setLocation(searchParams)
}

export const getLocalStorage = () => {
  let storage = null
  try {
    storage = window.localStorage
  } catch (e) {
    console.log('Local Storage Not Supported')
  }
  return storage
}

export const getSessionStorage = () => {
  let storage = null
  try {
    storage = window.sessionStorage
  } catch (e) {
    console.log('Session Storage Not Supported')
  }
  return storage
}

export const getCompanyId = () => {
  return getURLSearchParams().get('company_id')
}

export const dispatchHashChange = () => {
  return window.dispatchEvent(new HashChangeEvent('hashchange'))
}

// this can be called by any component to find the name of the current OS
// ie, osObject.name
export const getOS = () => {
  const osObject = {
    name: 'unknown',
    url: null,
  }
  const userAgent = window.navigator.userAgent,
    platform = window.navigator.platform,
    macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
    windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
    iosPlatforms = ['iPhone', 'iPad', 'iPod']

  if (macosPlatforms.indexOf(platform) !== -1) {
    osObject.name = 'Mac OS'
    osObject.url =
      'https://support.apple.com/guide/mac-help/allow-apps-to-detect-the-location-of-your-mac-mh35873/mac'
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    osObject.name = 'iOS'
    osObject.url = 'https://support.apple.com/en-us/HT207092'
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    osObject.name = 'Windows'
    osObject.url =
      'https://support.microsoft.com/en-us/windows/windows-10-location-service-and-privacy-3a8eee0a-5b0b-dc07-eede-2a5ca1c49088'
  } else if (/Android/.test(userAgent)) {
    osObject.name = 'Android'
    osObject.url =
      'https://support.google.com/chrome/answer/142065?co=GENIE.Platform%3DAndroid&hl=en&oco=0'
  } else if (!osObject.os && /Linux/.test(platform)) {
    osObject.name = 'Linux'
    osObject.url =
      'https://help.ubuntu.com/stable/ubuntu-help/privacy-location.html.en'
  }
  return osObject
}

// this can be called by any component to find the name of the current Browser
// ie, browserObj.name
export const getBrowser = () => {
  const browserObj = {
    name: 'unknown',
    url: null,
  }
  const sUsrAg = navigator.userAgent
  // The order matters here, and this may report false positives for unlisted browsers.
  if (sUsrAg.indexOf('Firefox') > -1) {
    browserObj.name = 'Mozilla Firefox'
    browserObj.url =
      'https://support.mozilla.org/en-US/kb/does-firefox-share-my-location-websites#w_how-does-it-work'
    // "Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:61.0) Gecko/20100101 Firefox/61.0"
  } else if (sUsrAg.indexOf('SamsungBrowser') > -1) {
    browserObj.name = 'Samsung Internet'
    browserObj.url =
      'https://www.samsung.com/uk/support/mobile-devices/how-do-i-switch-on-my-location-and-change-my-location-settings/'
    // "Mozilla/5.0 (Linux; Android 9; SAMSUNG SM-G955F Build/PPR1.180610.011) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/9.4 Chrome/67.0.3396.87 Mobile Safari/537.36
  } else if (sUsrAg.indexOf('Opera') > -1 || sUsrAg.indexOf('OPR') > -1) {
    browserObj.name = 'Opera'
    browserObj.url = 'https://help.opera.com/en/geolocation/'
    // "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_0) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/70.0.3538.102 Safari/537.36 OPR/57.0.3098.106"
  } else if (sUsrAg.indexOf('Trident') > -1) {
    browserObj.name = 'Internet Explorer'
    browserObj.url =
      'https://support.microsoft.com/en-us/topic/change-security-and-privacy-settings-for-internet-explorer-11-9528b011-664c-b771-d757-43a2b78b2afe'
    // "Mozilla/5.0 (Windows NT 10.0; WOW64; Trident/7.0; .NET4.0C; .NET4.0E; Zoom 3.6.0; wbx 1.0.0; rv:11.0) like Gecko"
  } else if (sUsrAg.indexOf('Edge') > -1) {
    browserObj.name = 'Microsoft Edge'
    browserObj.url =
      'https://support.microsoft.com/en-us/microsoft-edge/location-and-privacy-in-microsoft-edge-31b5d154-0b1b-90ef-e389-7c7d4ffe7b04'
    // "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36 Edge/16.16299"
  } else if (sUsrAg.indexOf('Chrome') > -1) {
    browserObj.name = 'Google Chrome'
    browserObj.url =
      'https://support.google.com/chrome/answer/142065?co=GENIE.Platform%3DAndroid&hl=en&oco=1'
    // "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Ubuntu Chromium/66.0.3359.181 Chrome/66.0.3359.181 Safari/537.36"
  } else if (sUsrAg.indexOf('Safari') > -1) {
    browserObj.name = 'Apple Safari'
    browserObj.url =
      'https://support.google.com/websearch/answer/179386?co=GENIE.Platform%3DiOS&hl=en#zippy=%2Cfor-a-website'
    // "Mozilla/5.0 (iPhone; CPU iPhone OS 11_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/11.0 Mobile/15E148 Safari/604.1 980x1306"
  }
  return browserObj
}
