import {
  SET_MY_WORK_ORDERS,
  SET_VISIT_STATUS,
  REMOVE_WORK_ORDER,
  SET_WORK_ORDER_STATUS,
  SET_CHECK_IN_GEOFENCE,
} from './actionType'

export function setMyWorkOrders(myWorkOrders) {
  return {
    type: SET_MY_WORK_ORDERS,
    myWorkOrders: myWorkOrders,
  }
}

export function setVisitStatus(workOrderNumber, visitStatus) {
  return {
    type: SET_VISIT_STATUS,
    workOrderNumber: workOrderNumber,
    visitStatus: visitStatus,
  }
}

export function setCheckInGeofence(checkInGeofence) {
  return {
    type: SET_CHECK_IN_GEOFENCE,
    checkInGeofence: checkInGeofence,
  }
}

export function removeWorkOrder(workOrderNumber) {
  return {
    type: REMOVE_WORK_ORDER,
    workOrderNumber: workOrderNumber,
  }
}

export function setWorkOrderStatus(workOrderNumber, workOrderStatus) {
  return {
    type: SET_WORK_ORDER_STATUS,
    workOrderNumber: workOrderNumber,
    workOrderStatus: workOrderStatus,
  }
}
