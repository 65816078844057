import {
  SET_SEARCHED_WORK_ORDERS,
  SET_SEARCH_OPTION,
  SET_SEARCHED_STOREID_WORK_ORDERS,
} from './actionType'

export function setSearchedWorkOrders(searchedWorkOrders) {
  return {
    type: SET_SEARCHED_WORK_ORDERS,
    searchedWorkOrders: searchedWorkOrders,
  }
}

export function setSearchedStoreIdWorkOrders(searchedStoreIdWorkOrders) {
  return {
    type: SET_SEARCHED_STOREID_WORK_ORDERS,
    searchedStoreIdWorkOrders: searchedStoreIdWorkOrders,
  }
}
export function setSearchOption(searchOption) {
  return {
    type: SET_SEARCH_OPTION,
    searchOption: searchOption,
  }
}
