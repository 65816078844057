import Card from '@material-ui/core/Card'
import { withStyles } from '@material-ui/core/styles'
import CardHeader from '@material-ui/core/CardHeader'
import Grid from '@material-ui/core/Grid'
import CardActions from '@material-ui/core/CardActions'
import { Typography } from '@material-ui/core'
import CardActionArea from '@material-ui/core/CardActionArea'
import Divider from '@material-ui/core/Divider'
import ClaimButton from './ClaimButton'
import CheckInButton from './CheckInButton'
import UpdateEtaButton from './UpdateEtaButton'
import VisitDetailButton from './VisitDetailButton'
import WorkOrderDetailButton from './WorkOrderDetailButton'
import CancelVisitButton from './CancelVisitButton'
import { withRouter } from 'react-router-dom'
import MatCardContent from './MatCardContent'
import { connect } from 'react-redux'
import { CHECK_IN, CHECK_OUT, FOLLOW_UP } from '../../globalConstants'
import MapIcon from '@material-ui/icons/Map'

const styles = (theme) => ({
  card: {
    margin: theme.spacing(1),
  },
  moreVertIcon: {
    marginTop: theme.spacing(1),
    color: theme.palette.primary.action,
  },
  cardHeader: {
    padding: theme.spacing(1, 0.5, 1, 2),
  },
  gridItemValue: {
    textAlign: 'right',
    flexGrow: 1,
    color: theme.palette.primary.alternate,
    margin: theme.spacing(2),
  },
  mapGrid: {
    paddingBottom: theme.spacing(1.5),
    color: theme.palette.primary.action,
  },
})

const WorkOrder = ({ classes, workOrder, history, session, shouldRender }) => {
  const workOrderVisits = workOrder.visits
  const firstWorkOrderVisit = workOrderVisits[0]
  const workOrderLocationData = workOrder.location
  const storeLocation = workOrderLocationData.locations[0]
  const storeAddress = workOrderLocationData.addresses[0]
  const expectedRepairTime = workOrder.expectedRepairTime

  const workOrderVisitsEmpty =
    (workOrderVisits !== null && !workOrderVisits.length) ||
    firstWorkOrderVisit.visitStatus === CHECK_OUT ||
    firstWorkOrderVisit.visitStatus === FOLLOW_UP // for reclaiming the workorder

  const cardActions = () => {
    return (
      <>
        <Divider variant={'middle'} />
        <CardActions>
          <Grid container justifyContent={'flex-end'}>
            <Grid item xs={12}>
              {!workOrderVisitsEmpty && (
                <CancelVisitButton visitId={firstWorkOrderVisit.visitId} />
              )}
              <WorkOrderDetailButton workOrder={workOrder} />
              {!workOrderVisitsEmpty ? (
                <>
                  <UpdateEtaButton visit={firstWorkOrderVisit} />
                  <CheckInButton workOrder={workOrder} />
                  <VisitDetailButton workOrder={workOrder} />
                </>
              ) : (
                <ClaimButton workOrder={workOrder} />
              )}
            </Grid>
          </Grid>
        </CardActions>
      </>
    )
  }

  const onClickCardActionArea = () => {
    const workOrderNumber = workOrder.workOrderNumber
    const technicianId = session.userInfo.technicianId
    let url = `/work-order-detail#?work_order_number=${workOrderNumber}&company_id=${workOrder.companyId}&technician_id=${technicianId}`
    if (!workOrderVisitsEmpty && firstWorkOrderVisit.visitStatus === CHECK_IN) {
      url = `/visit-detail#?visit_id=${
        firstWorkOrderVisit.visitId
      }&work_order_number=${workOrderNumber}&status=${
        workOrder.status
      }&company_id=${workOrder.companyId}&location=${
        storeLocation.locationName
      }&problem_code=${
        workOrder.problemCode
      }&visit_expiration=${window.encodeURIComponent(
        firstWorkOrderVisit.visitExpiration,
      )}&expected_repair_time=${window.encodeURIComponent(expectedRepairTime)}`
    }
    history.push(url)
  }

  return (
    <Grid item xs={12} sm={6}>
      <Card className={classes.card}>
        <CardHeader
          title={
            <Typography variant={'h6'}>
              {workOrderLocationData && storeLocation.locationName}
            </Typography>
          }
          className={classes.cardHeader}
        />
        <Divider variant={'middle'} />
        <CardActionArea onClick={onClickCardActionArea} disabled={shouldRender}>
          <MatCardContent workOrder={workOrder} />
        </CardActionArea>
        <a
          href={`http://maps.google.com/?q= ${storeAddress.address} ${storeAddress.city}
                 ${storeAddress.region} ${storeAddress.postalCode}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Grid container className={classes.mapGrid}>
            <Grid
              item
              container
              xs={2}
              justifyContent="center"
              alignContent="center"
            >
              <MapIcon fontSize="large" />
            </Grid>
            <Grid item xs={10}>
              <Typography>{storeAddress.address}</Typography>
              <Typography>
                {storeAddress.city}, {storeAddress.region}{' '}
                {storeAddress.postalCode}
              </Typography>
            </Grid>
          </Grid>
        </a>
        {cardActions()}
      </Card>
    </Grid>
  )
}

const mapStateToProps = (state) => {
  return {
    session: state.authCredentialsReducer.auth.session,
    shouldRender: state.linearProgressReducer.shouldRender,
  }
}

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, null)(WorkOrder)),
)
