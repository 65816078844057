import { Component } from 'react'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { gql } from 'apollo-boost'
import { withApollo } from 'react-apollo'
import { getURLSearchParams } from '../../../windowManager'
import {
  LOADING,
  FAILED_TO_FETCH,
  NO_CACHE,
  PROVIDE,
} from '../../../globalConstants'
import { connect } from 'react-redux'
import { setRemedy } from './actionCreator'
import { setMessage } from '../../GeoLocation/actionCreator'
import { toggleRender } from '../../MatLinearProgress/actionCreator'
import { updateWorkOrderQuery } from '../UpdateWorkOrderQuery'
import FormHelperText from '@material-ui/core/FormHelperText'
import { setRemedyErrorValidation } from '../../VisitDetail/FixedButton/actionCreator'

export const getRemediesQuery = gql`
  query GetRemedies($woNum: String, $problemCode: String, $causeCode: String) {
    getRemedies(
      woNum: $woNum
      problemCode: $problemCode
      causeCode: $causeCode
    ) {
      failureCode
    }
  }
`

export const getRemedy = gql`
  query GetWorkOrder(
    $woNum: String!
    $companyId: String!
    $technicianId: String!
  ) {
    getWorkOrder(
      woNum: $woNum
      companyId: $companyId
      technicianId: $technicianId
    ) {
      remedy
    }
  }
`

export class RemedyDropdown extends Component {
  constructor() {
    super()
    this.state = {
      remedies: [],
      displayMessage: LOADING,
      loadingRemedy: false,
    }
  }

  getRemediesList = async (cause) => {
    const { client, toggleRender } = this.props
    const searchParams = getURLSearchParams()
    const workOrderNumber = searchParams.get('work_order_number')
    const problemCode = searchParams.get('problem_code')
    try {
      toggleRender(true)
      const remediesData = await client.query({
        query: getRemediesQuery,
        variables: {
          woNum: workOrderNumber,
          problemCode: problemCode,
          causeCode: cause,
        },
        fetchPolicy: NO_CACHE,
      })
      const remedies = remediesData.data.getRemedies
      if (remedies.length) {
        this.setState({
          remedies: remedies.map(
            (remediesObject) => remediesObject.failureCode,
          ),
        })
        this.getWorkOrderRemedy(workOrderNumber)
      } else {
        this.setState({
          displayMessage: 'No remedies found',
        })
      }
    } catch (e) {
      this.setState({
        displayMessage: FAILED_TO_FETCH,
      })
    } finally {
      toggleRender(false)
    }
  }

  getWorkOrderRemedy = async (workOrderNumber) => {
    const { session, client, setRemedy, toggleRender } = this.props
    let response = {
      data: {
        getWorkOrder: [],
      },
    }
    try {
      toggleRender(true)
      this.setState({
        displayMessage: LOADING,
        loadingRemedy: true,
      })
      const searchParams = getURLSearchParams()
      response = await client.query({
        query: getRemedy,
        variables: {
          woNum: workOrderNumber,
          companyId: searchParams.get('company_id'),
          technicianId: session.userInfo.technicianId,
        },
        fetchPolicy: NO_CACHE,
      })
      setRemedy(response.data.getWorkOrder[0].remedy || '')
    } catch (e) {
      this.setState({
        displayMessage: FAILED_TO_FETCH,
      })
    } finally {
      this.setState({
        loadingRemedy: false,
      })
      toggleRender(false)
    }
  }

  componentDidMount = () => {
    const { selectedCause } = this.props
    if (!selectedCause) {
      setRemedy('')
    } else {
      this.setState({
        remedies: [],
        displayMessage: LOADING,
      })
      this.getRemediesList(selectedCause)
    }
  }

  componentDidUpdate = (prevProps) => {
    const { selectedCause, setRemedy, setRemedyErrorValidation } = this.props
    if (prevProps.selectedCause !== selectedCause) {
      if (!selectedCause) {
        setRemedy('')
        setRemedyErrorValidation(false)
      } else {
        this.setState({
          remedies: [],
          displayMessage: LOADING,
        })
        this.getRemediesList(selectedCause)
      }
    }
  }

  updateRemedy = async (remedy) => {
    const {
      client,
      setMessage,
      toggleRender,
      setRemedy,
      setRemedyErrorValidation,
    } = this.props
    const searchParams = getURLSearchParams()
    const workOrderNumber = searchParams.get('work_order_number')
    let updateResponse = {
      data: {
        updateWorkOrder: {},
      },
    }
    let message = null
    try {
      toggleRender(true)
      setMessage(`Updating remedy for Work Order #${workOrderNumber}`)
      updateResponse = await client.mutate({
        mutation: updateWorkOrderQuery,
        variables: {
          workorder: { remedy: remedy },
          woNum: workOrderNumber,
        },
        fetchPolicy: NO_CACHE,
      })
    } catch (e) {
      message = `Unable to update remedy for Work Order #${workOrderNumber}`
    } finally {
      toggleRender(false)
      if (updateResponse.data.updateWorkOrder.message) {
        setRemedy(remedy)
        setMessage(
          `Successfully updated remedy for Work Order #${workOrderNumber}`,
        )
        setRemedyErrorValidation(false)
      } else {
        setMessage(message)
      }
    }
  }

  handleChange = async (event) => {
    const { remedy } = this.props
    const newRemedy = event.target.value
    if (newRemedy !== remedy) {
      this.updateRemedy(newRemedy)
    }
  }

  render = () => {
    const remediesLength = this.state.remedies.length
    const showLoadingState = !remediesLength || this.state.loadingRemedy
    const { selectedCause, remedy, showRemedyErrorValidation } = this.props
    const useProvideText = !remedy ? PROVIDE : ''

    return (
      selectedCause && (
        <FormControl fullWidth error={showRemedyErrorValidation && !remedy}>
          <InputLabel>
            {showLoadingState
              ? this.state.displayMessage
              : `${useProvideText} Remedy`}
          </InputLabel>
          <Select
            value={remedy || ''}
            onChange={this.handleChange}
            disabled={!remediesLength}
          >
            <MenuItem value={''} />
            {this.state.remedies.map((remedy, index) => {
              return (
                <MenuItem value={remedy} key={index}>
                  {remedy}
                </MenuItem>
              )
            })}
          </Select>
          {showRemedyErrorValidation && !remedy && (
            <FormHelperText>*Please choose a remedy.</FormHelperText>
          )}
        </FormControl>
      )
    )
  }
}

export const mapStateToProps = (state) => {
  return {
    selectedCause: state.causeReducer.cause,
    remedy: state.remedyReducer.remedy,
    session: state.authCredentialsReducer.auth.session,
    showRemedyErrorValidation:
      state.fixedButtonReducer.showRemedyErrorValidation,
  }
}

const mapDispatchToProps = {
  setRemedy,
  setMessage,
  toggleRender,
  setRemedyErrorValidation,
}

export default withApollo(
  connect(mapStateToProps, mapDispatchToProps)(RemedyDropdown),
)
