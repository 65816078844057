import { Grid } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import ActionButton from '../ActionButton/ActionButton'
import WorkIcon from '@material-ui/icons/Work'
import { SERVICE_TECH } from '../../globalConstants'
import Avatar from '@material-ui/core/Avatar'
import { getURLSearchParams } from '../../windowManager'
import WarningIcon from '@material-ui/icons/Warning'
import RoomIcon from '@material-ui/icons/Room'
import CancelVisitButton from '../../components/WorkOrder/CancelVisitButton'

const styles = (theme) => ({
  gridParent: {
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.action,
  },
  avatar: {
    height: theme.spacing(8),
    width: theme.spacing(8),
    backgroundColor: theme.palette.secondary.action,
  },
  avatarSecondary: {
    marginLeft: theme.spacing(4),
  },
  feedbackIcons: {
    color: theme.palette.primary.action,
  },
  commonClass: {
    padding: theme.spacing(2, 0),
  },
  message: {
    maxWidth: 320,
  },
})

export const OutsideGeofenceScreen = ({ classes }) => {
  const visitId = getURLSearchParams().get('visit_id')
  const commonGridProps = {
    container: true,
    justifyContent: 'center',
    className: classes.commonClass,
  }
  return (
    <Grid
      className={classes.gridParent}
      alignContent="center"
      container
      justifyContent="center"
    >
      <Grid item xs={10} sm={5}>
        <Grid {...commonGridProps}>
          <Typography variant={'h5'}>{SERVICE_TECH}</Typography>
        </Grid>
        <Grid {...commonGridProps}>
          <Avatar className={classes.avatar}>
            <WarningIcon className={classes.feedbackIcons} fontSize={'large'} />
          </Avatar>
          <Avatar className={`${classes.avatar} ${classes.avatarSecondary}`}>
            <RoomIcon className={classes.feedbackIcons} fontSize={'large'} />
          </Avatar>
        </Grid>
        <Grid {...commonGridProps}>
          <Typography className={classes.message}>
            Your device indicates you checked-in more than .2 miles from the
            front door. If you would like to fix this, cancel the visit and try
            again.
          </Typography>
        </Grid>
        <Grid {...commonGridProps}>
          <CancelVisitButton
            visitId={visitId}
            variant={'contained'}
            fullWidth={false}
            geoWarning
          />
        </Grid>
        <Grid {...commonGridProps}>
          <ActionButton
            text={'Ignore and return'}
            onClick={() => window.history.back()}
            icon={<WorkIcon />}
            variant={'contained'}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(OutsideGeofenceScreen)
