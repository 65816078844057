import { Link } from 'react-router-dom'
import UpdateIcon from '@material-ui/icons/Update'
import ActionButton from '../ActionButton/ActionButton'
import { connect } from 'react-redux'
import { getURLSearchParams, getCompanyId } from '../../windowManager'

export const UpdateEtaButton = ({ visit, session }) => {
  const searchParams = getURLSearchParams()
  const wonum = searchParams.get('wonum')

  return (
    visit.visitStatus === 'Assigned' && (
      <Link
        to={`/update-eta#?visit_id=${
          visit.visitId
        }&company_id=${getCompanyId()}&wonum=${wonum}`}
      >
        <ActionButton
          text={`set eta as ${session.userInfo.name}`}
          icon={<UpdateIcon />}
          data-cy="update-eta-button"
          fullWidth
        />
      </Link>
    )
  )
}

const mapStateToProps = (state) => {
  return {
    session: state.authCredentialsReducer.auth.session,
  }
}

export default connect(mapStateToProps, null)(UpdateEtaButton)
