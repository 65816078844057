import Cancel from '@material-ui/icons/Cancel'
import { withRouter } from 'react-router-dom'
import ActionButton from '../ActionButton/ActionButton'
import { withApollo } from 'react-apollo'
import { gql } from 'apollo-boost'
import { NO_CACHE, CANCEL, VISIT } from '../../globalConstants'
import { setMessage } from '../GeoLocation/actionCreator'
import { toggleRender } from '../MatLinearProgress/actionCreator'
import { removeWorkOrder } from '../MyWork/actionCreator'
import { connect } from 'react-redux'
import { dispatchHashChange, getURLSearchParams } from '../../windowManager'

export const UPDATE_VISIT = gql`
  mutation UpdateVisit($visit: VisitInput!, $visitId: String!) {
    updateVisit(visit: $visit, visitId: $visitId) {
      visitId
      visitStatus
    }
  }
`
export const CancelVisitButton = ({
  setMessage,
  toggleRender,
  client,
  removeWorkOrder,
  session,
  visitId,
  variant = 'text',
  fullWidth = true,
  geoWarning = false,
}) => {
  const workOrderNumber = getURLSearchParams().get('wonum')

  const onClickCancelVisit = async () => {
    let response = {}
    // we assume we will fail
    let message = `Unable to ${CANCEL} ${VISIT} for work order #${workOrderNumber}`
    try {
      toggleRender(true)
      setMessage(
        `Attempting to ${CANCEL} ${VISIT} for work order #${workOrderNumber}`,
      )
      const body = {
        visit_status: 'Cancelled',
        reference_id: workOrderNumber,
      }
      response = await client.mutate({
        mutation: UPDATE_VISIT,
        variables: {
          visit: body,
          visitId: visitId,
        },
        fetchPolicy: NO_CACHE,
      })
      message =
        /* istanbul ignore next */ response.data.updateVisit.visitId && // can't reach code due to graph ql so using istanbul ignore
        `Successfully cancelled visit for work order #${workOrderNumber}`
      removeWorkOrder(workOrderNumber)
      geoWarning ? window.history.back() : dispatchHashChange()
    } catch (e) {
      message = `Unable to ${CANCEL} ${VISIT} for work order #${workOrderNumber}`
    } finally {
      toggleRender(false)
      setMessage(message)
    }
  }

  return (
    <ActionButton
      text={`${CANCEL} visit as ${session.userInfo.name}`}
      icon={<Cancel />}
      variant={variant}
      onClick={onClickCancelVisit}
      fullWidth={fullWidth}
    />
  )
}

const mapDispatchToProps = {
  setMessage,
  toggleRender,
  removeWorkOrder,
}

const mapStateToProps = (state) => {
  return {
    session: state.authCredentialsReducer.auth.session,
  }
}

export default withApollo(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(CancelVisitButton)),
)
