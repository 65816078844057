import { Component } from 'react'
import AppHeader from '../../Header/Header'
import { WORK_ORDER_PHOTOS } from '../../../globalConstants'
import ListSubheader from '@material-ui/core/ListSubheader'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import ErrorIcon from '@material-ui/icons/Error'
import { toggleRender } from '../../MatLinearProgress/actionCreator'
import { NO_CACHE } from '../../../globalConstants'
import { gql } from 'apollo-boost'
import { withApollo } from 'react-apollo'
import { connect } from 'react-redux'
import { getURLSearchParams, getCompanyId } from '../../../windowManager'
import { setMessage } from '../../UserFeedback/actionCreator'
import transparentPixel from './1x1.png'
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty'

export const GET_WORK_ORDER = gql`
  query GetWorkOrder(
    $woNum: String!
    $companyId: String!
    $technicianId: String!
  ) {
    getWorkOrder(
      woNum: $woNum
      companyId: $companyId
      technicianId: $technicianId
    ) {
      attachments {
        attachmentId
        url
        workOrderNumber
      }
    }
  }
`

export const GET_WORK_ORDER_ATTACHMENT = gql`
  query GetAttachment($woNum: String!, $attachmentId: String!) {
    getAttachment(woNum: $woNum, attachmentId: $attachmentId)
  }
`

const styles = (theme) => ({
  typography: {
    color: theme.palette.secondary.action,
    backgroundColor: theme.palette.primary.alternate,
    padding: theme.spacing(1, 1.5),
    // these three lines ensure long file names don't break out of the UI
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  img: {
    width: '100%',
    borderWidth: theme.spacing(0, 0.5, 0.5, 0.5),
    borderColor: theme.palette.primary.alternate,
    borderStyle: 'solid',
    backgroundColor: theme.palette.primary.main,
  },
  gridItem: {
    height: '100%',
    padding: theme.spacing(0.5, 1, 0.5, 1),
    position: 'relative',
  },
  feedbackIcon: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    margin: theme.spacing(-1, 0, 0, -2),
    color: theme.palette.secondary.action,
  },
})

export class WorkOrderPhotos extends Component {
  // component is not mounted here, default to false
  _isMounted = false
  constructor() {
    super()
    this.state = {
      attachments: [],
    }
  }

  componentWillUnmount() {
    // this helps ensure API Calls coming back to an unmounted component...
    // do not cause memory leaks.
    // normally, not a big deal, but we are talking about a LARGE amount of data...
    // so we need to be a little extra careful
    this._isMounted = false
  }

  getWorkOrderAttachment = async (index) => {
    const { client } = this.props
    const searchParams = getURLSearchParams()
    const workOrderNumber = searchParams.get('work_order_number')
    // we get a reference to the attachments in state and work with that
    // we do not directly modify state
    const { attachments } = this.state
    // this is a reference to the current attachment we are loading
    const attachment = attachments[index]
    // this is a reference to the next attachment in line to be loaded
    const nextAttachment = attachments[index + 1]
    try {
      const result = await client.query({
        query: GET_WORK_ORDER_ATTACHMENT,
        variables: {
          woNum: workOrderNumber,
          attachmentId: attachment.attachmentId,
        },
        fetchPolicy: NO_CACHE,
      })
      const src = result.data.getAttachment
      // is the src truthy?
      if (src) {
        // yes? set the src to the attachment to the raw image data
        attachment.src = `data:image/png;base64,${src}`
      } else {
        // no? something bad happened, set error prop to true
        attachment.error = true
      }
    } catch (e) {
      // this will get tripped in case of a server error
      attachment.error = true
    } finally {
      // regardless of success or failure, the component is loaded
      attachment.loaded = true
      // as it's loaded, it cannot be in the loading phase right now
      attachment.loading = false
      if (nextAttachment) {
        // the next attachment in line is flagged as next for loading
        nextAttachment.loading = true
      }
      // if the component is still mounted...
      // we now set the state to the attachments copy / reference we've been working with
      this._isMounted && this.setState({ attachments: attachments })
      // code continues in componentDidUpdate...
      // because we just updated the state
    }
  }

  componentDidUpdate = () => {
    // be VERY careful updating the state in this compnent beyond what is already done
    // ANY change to state / props will cause the code below to run
    // shouldn't be a problem due to how it's designed, but wanted to call it out
    const { attachments } = this.state
    const attachmentsLength = attachments.length
    // we loop through all the attachments in state
    // looking for one that has not been loaded
    for (var i = 0; i < attachmentsLength; i++) {
      const attachment = attachments[i]
      if (!attachment.loaded) {
        // we found one that hasn't been loaded yet, so let's try to load it
        // we need to pass the index (here as i) so we can have a reference...
        // in the called method getWorkOrderAttachment
        this.getWorkOrderAttachment(i)
        // break out of the loop
        break
      }
    }
  }

  componentDidMount = async () => {
    // the component is mounted, we note this in a variable for future use
    this._isMounted = true
    const { toggleRender, client, setMessage } = this.props
    const searchParams = getURLSearchParams()
    const workOrderNumber = searchParams.get('work_order_number')
    const technicianId = searchParams.get('technician_id')
    let message = null
    try {
      toggleRender(true)
      setMessage('Attempting to get a list of attachments...')
      const result = await client.query({
        query: GET_WORK_ORDER,
        variables: {
          woNum: workOrderNumber,
          companyId: getCompanyId(),
          technicianId: technicianId,
        },
        fetchPolicy: NO_CACHE,
      })
      const attachments = result.data.getWorkOrder[0].attachments
      const attachmentsLength = attachments.length
      // we iterate through the attachments if they have length
      // AND if the component is currently mounted
      if (attachmentsLength && this._isMounted) {
        for (var i = 0; i < attachmentsLength; i++) {
          // we set all attachments by default to unloaded, and set the src to a transparent pixel
          // to avoid rendering a broken image while we wait for 'real' image data
          attachments[i].src = transparentPixel
          attachments[i].loaded = false
        }
        // by default we set the first image to a status of loading
        attachments[0].loading = true
        // we set the state of attachments to the attachments we recieved from the API call
        // we updated state, so the code now continues in componentDidUpdate
        this.setState({
          attachments: attachments,
        })
      } else {
        message = 'No attachments for this work order.'
      }
    } catch (e) {
      message = e.message
    } finally {
      setMessage(message)
      toggleRender(false)
    }
  }

  render = () => {
    const { classes } = this.props
    const searchParams = getURLSearchParams()
    const workOrderNumber = searchParams.get('work_order_number')
    return (
      <>
        <AppHeader arrowBack title={WORK_ORDER_PHOTOS} />
        <ListSubheader>
          {this.state.attachments.length} Photos for Work Order #
          {workOrderNumber}
        </ListSubheader>
        <Grid container>
          {this.state.attachments.map((attachment, key) => (
            <Grid item className={classes.gridItem} key={key} xs={12} sm={6}>
              <Typography className={classes.typography}>
                {attachment.url}
              </Typography>
              <img
                alt={attachment.url}
                className={classes.img}
                src={attachment.src}
              />
              {!attachment.loaded && attachment.loading && (
                // is the current attachment both NOT loaded...
                // AND currently loading?
                // then show them the progress bar
                <CircularProgress className={classes.feedbackIcon} />
              )}
              {attachment.error && (
                // is the attachment in an error state...?
                // if so, show the user an error icon
                <ErrorIcon
                  fontSize={'large'}
                  className={classes.feedbackIcon}
                />
              )}
              {!attachment.loaded && !attachment.loading && (
                // is the attachment both NOT loaded...
                // AND not loading?
                // the image is currently in the que, so...
                // show an hourglass (material UI's way of saying: 'waiting')
                <HourglassEmptyIcon
                  fontSize={'large'}
                  className={classes.feedbackIcon}
                />
              )}
            </Grid>
          ))}
        </Grid>
      </>
    )
  }
}

const mapDispatchToProps = {
  toggleRender,
  setMessage,
}

export default withApollo(
  connect(null, mapDispatchToProps)(withStyles(styles)(WorkOrderPhotos)),
)
