import Button from '@material-ui/core/Button'
import { Link } from 'react-router-dom'
import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { getURLSearchParams, getCompanyId } from '../../windowManager'
import WorkOutlineIcon from '@material-ui/icons/WorkOutline'

const styles = (theme) => ({
  button: {
    padding: theme.spacing(2),
    color: theme.palette.primary.action,
    backgroundColor: theme.palette.secondary.action,
  },
  actionText: {
    marginLeft: theme.spacing(1),
  },
})

const WorkOrderDetailButton = ({ classes, session }) => {
  const searchParams = getURLSearchParams()
  const workOrderNumber = searchParams.get('work_order_number')
  const technicianId = session.userInfo.technicianId

  return (
    <Link
      to={`/work-order-detail#?company_id=${getCompanyId()}&work_order_number=${workOrderNumber}&technician_id=${technicianId}`}
    >
      <Button
        fullWidth
        className={classes.button}
        variant={'contained'}
        aria-label="Work Order Detail"
      >
        <WorkOutlineIcon />
        <Typography className={classes.actionText} variant={'button'}>
          Work Order Detail
        </Typography>
      </Button>
    </Link>
  )
}

export const mapStateToProps = (state) => {
  return {
    session: state.authCredentialsReducer.auth.session,
  }
}

export default connect(
  mapStateToProps,
  null,
)(withStyles(styles)(WorkOrderDetailButton))
