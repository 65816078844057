import {
  SET_AUTH_CREDENTIALS,
  SET_COMPANY_ID,
  SET_COMPANY_NAME,
  SET_PROFILES,
} from './actionType'

export function setAuthCredentials(auth) {
  return {
    type: SET_AUTH_CREDENTIALS,
    auth: auth,
  }
}

export function setCompanyId(companyId) {
  return {
    type: SET_COMPANY_ID,
    companyId: companyId,
  }
}

export function setCompanyName(companyName) {
  return {
    type: SET_COMPANY_NAME,
    companyName: companyName,
  }
}

export function setProfiles(profiles) {
  return {
    type: SET_PROFILES,
    profiles: profiles,
  }
}
