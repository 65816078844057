import 'date-fns'
import { Component } from 'react'
import { connect } from 'react-redux'
import DateFnsUtils from '@date-io/date-fns'
import { setSelectedDateTime } from './actionCreator'
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { getURLSearchParams } from '../../windowManager'

export class DatePicker extends Component {
  constructor() {
    super()
    this.state = {
      open: false,
    }
  }
  onHashChange = () => {
    const searchParams = getURLSearchParams()
    const modal = searchParams.get('modal')
    this.setState({ open: Boolean(modal) })
  }

  componentDidMount = () => {
    window.addEventListener('hashchange', this.onHashChange)
    this.onHashChange()
  }

  componentWillUnmount = () => {
    window.removeEventListener('hashchange', this.onHashChange)
  }

  render = () => {
    const { setSelectedDateTime, selectedDateTime } = this.props

    const handleDateChange = (date) => {
      setSelectedDateTime(date)
    }

    const onOpenDateTimePicker = () => {
      const searchParams = getURLSearchParams()
      searchParams.set('modal', true)
      window.location.href = `#?${searchParams.toString()}`
    }

    const onCloseDateTimePicker = () => {
      window.history.back()
    }

    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DateTimePicker
          label={!selectedDateTime ? 'Please enter ETA' : 'Date Time'}
          fullWidth
          disablePast
          inputVariant="outlined"
          InputLabelProps={{
            shrink: Boolean(selectedDateTime),
          }}
          value={selectedDateTime}
          onChange={handleDateChange}
          onOpen={onOpenDateTimePicker}
          onClose={onCloseDateTimePicker}
          open={this.state.open}
        />
      </MuiPickersUtilsProvider>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    selectedDateTime: state.selectedDateReducer.selectedDateTime,
  }
}

const mapDispatchToProps = {
  setSelectedDateTime,
}

export default connect(mapStateToProps, mapDispatchToProps)(DatePicker)
