/* eslint-disable react-hooks/exhaustive-deps */
import { Container, Grid, Typography } from '@material-ui/core'
import { useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import AppHeader from '../../Header/Header'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import PhotoIcon from '@material-ui/icons/Photo'
import { getURLSearchParams } from '../../../windowManager'
import ActionButton from '../../ActionButton/ActionButton'
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto'
import { setMessage } from '../../UserFeedback/actionCreator'
import { connect } from 'react-redux'
import { gql } from 'apollo-boost'
import { NO_CACHE } from '../../../globalConstants'
import { withApollo } from 'react-apollo'
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty'
import ErrorIcon from '@material-ui/icons/Error'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CircularProgress from '@material-ui/core/CircularProgress'
import Alert from '@material-ui/lab/Alert'

const UPLOAD_IMAGE = gql`
  mutation UploadImage($woNum: String, $image: ImageInput) {
    uploadImage(woNum: $woNum, image: $image) {
      workOrderNumber
    }
  }
`

const UPLOAD_PHOTOS = 'Upload Photos'
const MAX_FILES = 5

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      padding: theme.spacing(2, 1, 1, 1),
      backgroundColor: theme.palette.secondary.action,
    },
    photoIcon: {
      margin: theme.spacing(0, 2, 0, 0.6),
    },
    bodyTextContainer: {
      padding: theme.spacing(2, 1),
    },
    imagePreview: {
      width: '100%',
      borderWidth: theme.spacing(0, 0.5, 0.5, 0.5),
      borderColor: theme.palette.primary.alternate,
      borderStyle: 'solid',
      backgroundColor: theme.palette.primary.main,
      display: 'block',
    },
    gridThumb: {
      padding: theme.spacing(1),
      width: '100%',
    },
    thumbTypography: {
      color: theme.palette.secondary.action,
      backgroundColor: theme.palette.primary.main,
      padding: theme.spacing(1, 1.5),
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    statusText: {
      color: theme.palette.secondary.action,
    },
    imageIcon: {
      color: theme.palette.secondary.action,
      marginRight: theme.spacing(1.5),
    },
    statusHeader: {
      padding: theme.spacing(1, 1.5),
      backgroundColor: theme.palette.primary.alternate,
    },
    progressIcon: {
      margin: theme.spacing(0, 2, 0, 2.5),
    },
    introHeader: {
      margin: theme.spacing(1, 0, 2, 0),
    },
    thumbContainer: {
      marginTop: theme.spacing(1),
    },
    alert: {
      margin: theme.spacing(3, 1, 0, 1),
    },
    actionBar: {
      paddingRight: theme.spacing(1),
    },
  }),
)

/* istanbul ignore next */ const ImageUpload = ({ setMessage, client }) => {
  const classes = useStyles()
  const [files, setFiles] = useState([])
  const filesLength = files.length
  // fileIndex tracks which file is currently uploading from the files array
  const [fileIndex, setFileIndex] = useState(0)
  // toggle is a boolean we flip back and forth to trigger the custom useEffect
  // further down in the class
  const [toggle, setToggle] = useState(false)
  const searchParams = getURLSearchParams()
  const workOrderNumber = searchParams.get('work_order_number')
  const imageIconClass = classes.imageIcon
  const statusTextClass = classes.statusText
  const circularProgressSize = 24

  // delivers the thumb previews and user feedback on individual upload progress
  const thumbs = files.map((file, index) => (
    <Grid item key={index} sm={6} className={classes.gridThumb}>
      <Typography className={classes.thumbTypography}>{file.path}</Typography>
      <Grid container className={classes.statusHeader} alignItems="center">
        {
          // is the file both not loaded and not loading?
          // then it's queued
          !file.loaded && !file.loading && (
            <>
              <HourglassEmptyIcon className={imageIconClass} />
              <Typography component="span" className={statusTextClass}>
                Queued {`${index + 1} of ${filesLength}`}
              </Typography>
            </>
          )
        }
        {
          // did the error prop get tripped?
          // then the upload on this image failed
          file.error && (
            <>
              <ErrorIcon className={imageIconClass} />
              <Typography component="span" className={statusTextClass}>
                Upload Failed
              </Typography>
            </>
          )
        }
        {
          // is the file both not loaded, and currently loading?
          // then it is the one currently being uploaded
          !file.loaded && file.loading && (
            <>
              <CircularProgress
                size={circularProgressSize}
                className={imageIconClass}
              />
              <Typography component="span" className={statusTextClass}>
                Uploading...
              </Typography>
            </>
          )
        }
        {
          // is the file not in an error state?
          // is the file also not loading?
          // is the file also done being loaded?
          // then the process has completed successfully
          !file.error && !file.loading && file.loaded && (
            <>
              <CheckCircleIcon className={imageIconClass} />
              <Typography component="span" className={statusTextClass}>
                Upload Successful
              </Typography>
            </>
          )
        }
      </Grid>
      <img
        src={file.preview}
        alt={file.name}
        className={classes.imagePreview}
      />
    </Grid>
  ))

  // this works, but couldn't get async / await working on this,
  // so went with a promise instead
  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result.split(',')[1])
      reader.onerror = (error) => reject(error)
    })

  const { fileRejections, getRootProps, getInputProps } = useDropzone({
    // array of acceptable image types
    accept: 'image/jpeg, image/png, image/jpg',
    maxFiles: MAX_FILES,
    // onDrop is fired after the user has provided some images for upload
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file, index) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            loaded: false,
            // is this the first file in the array?
            // if so, set it's loading status to true, cause FIFO
            loading: index === 0 ? true : false,
            error: false,
          }),
        ),
      )
      // this the beginning of the process,
      // so we set the file index to 0 (first file)
      setFileIndex(0)
      // we flip the toggle boolean to start the uploadImage process
      // in the custom useEffect further down in the class
      setToggle(!toggle)
    },
  })

  const uploadImage = async () => {
    // get a handle to the current file we are uploading
    const file = files[fileIndex]
    // get a handle to the next file index
    const nextIndex = fileIndex + 1
    // get a handle to the next file we will be uploading
    const nextFile = files[nextIndex]
    try {
      await client.query({
        query: UPLOAD_IMAGE,
        variables: {
          woNum: workOrderNumber,
          image: {
            name: file.name,
            description: file.name,
            base64_data: await toBase64(file),
          },
        },
        fetchPolicy: NO_CACHE,
      })
    } catch (e) {
      setMessage(`Image upload service failed. Please try again later.`)
      file.error = true
    } finally {
      // we set loaded to true, and loading to false,
      // as regardless of what state the uploaded ended up in,
      // succeess or failure, we are done with the process now,
      // so loaded is always true, and loading is always false here
      file.loaded = true
      file.loading = false
      // if the nextFile is truthy, then set its state to loading
      // NOTE: can't short-circuit logic like this
      if (nextFile) {
        nextFile.loading = true
      }
      // update the file index to indicate we are moving on to the next image
      setFileIndex(nextIndex)
      // flip the toggle boolean to trigger the custom useEffect logic
      setToggle(!toggle)
    }
  }

  useEffect(() => {
    // this is called when the component mounts,
    // and whenever the toggle boolean is flipped.
    // is the fileIndex less than the total number of files?
    // if yes, we have another image to attempt an upload on...
    // if no, nothing happens here, and we are done.
    fileIndex < filesLength && uploadImage()
  }, [toggle])

  // this fires if the user tries to select too many images
  useEffect(() => {
    fileRejections.length > MAX_FILES &&
      setMessage(
        `Too many images selected. Please choose ${MAX_FILES} or less.`,
      )
  }, [fileRejections.length])

  return (
    <>
      <AppHeader arrowBack title={UPLOAD_PHOTOS} />
      <Container className={classes.container}>
        {fileIndex < filesLength ? (
          <Grid container className={classes.introHeader}>
            <Grid container alignItems="center">
              <CircularProgress
                className={classes.progressIcon}
                size={circularProgressSize}
              />
              <Typography component="span">
                <strong>
                  Currently uploading file {fileIndex + 1} / {filesLength} ...
                </strong>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Alert
                variant="filled"
                severity="warning"
                className={classes.alert}
              >
                Please stay on this page until your images have finished
                uploading.
              </Alert>
            </Grid>
          </Grid>
        ) : (
          <>
            <Grid container alignItems="center">
              <PhotoIcon fontSize="large" className={classes.photoIcon} />
              <Typography variant="h6" component="span">
                How to {UPLOAD_PHOTOS}
              </Typography>
            </Grid>
            <Grid className={classes.bodyTextContainer}>
              <Typography>
                Please click on the {UPLOAD_PHOTOS} button below to select up to{' '}
                <strong>{MAX_FILES}</strong> photos to attach to work order
                number <strong>{workOrderNumber}</strong>.
              </Typography>
            </Grid>
            <Grid container justifyContent="flex-end">
              <Grid
                item
                {...getRootProps({ className: 'dropzone' })}
                className={classes.actionBar}
              >
                <input {...getInputProps()} />
                <ActionButton text={UPLOAD_PHOTOS} icon={<AddAPhotoIcon />} />
              </Grid>
            </Grid>
          </>
        )}
        <Grid container className={classes.thumbContainer}>
          {thumbs}
        </Grid>
      </Container>
    </>
  )
}

const mapDispatchToProps = {
  setMessage,
}

export default withApollo(connect(null, mapDispatchToProps)(ImageUpload))
