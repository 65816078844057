import {
  RESET_ERROR_FIXED_BUTTON,
  SET_ERROR_REMEDY_FIXED_BUTTON,
  SET_ERROR_CAUSE_FIXED_BUTTON,
  SET_ERROR_VISIT_NOTES_FIXED_BUTTON,
} from './actionType'

export function resetFixedErrorValidation() {
  return {
    type: RESET_ERROR_FIXED_BUTTON,
  }
}

export function setCauseErrorValidation(showCauseErrorValidation) {
  return {
    type: SET_ERROR_CAUSE_FIXED_BUTTON,
    showCauseErrorValidation: showCauseErrorValidation,
  }
}

export function setRemedyErrorValidation(showRemedyErrorValidation) {
  return {
    type: SET_ERROR_REMEDY_FIXED_BUTTON,
    showRemedyErrorValidation: showRemedyErrorValidation,
  }
}

export function setVisitNotesErrorValidation(showVisitNoteErrorValidation) {
  return {
    type: SET_ERROR_VISIT_NOTES_FIXED_BUTTON,
    showVisitNoteErrorValidation: showVisitNoteErrorValidation,
  }
}
