import { SET_CAUSE } from './actionType'

export const initialState = {
  cause: '',
}

export default function causeReducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_CAUSE: {
      const newState = {
        ...state,
        cause: action.cause,
      }
      return newState
    }
    default:
      return state
  }
}
