import {
  SET_MY_WORK_ORDERS,
  SET_VISIT_STATUS,
  REMOVE_WORK_ORDER,
  SET_WORK_ORDER_STATUS,
  SET_CHECK_IN_GEOFENCE,
} from './actionType'

const initialState = {
  myWorkOrders: [],
}

export default function myWorkOrdersReducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_MY_WORK_ORDERS: {
      const newState = {
        ...state,
        myWorkOrders: action.myWorkOrders,
      }
      return newState
    }
    case SET_VISIT_STATUS: {
      const workOrders = state.myWorkOrders.map((wo) => {
        let updatedWorkOrder = wo
        if (wo.workOrderNumber === action.workOrderNumber) {
          const visits = Object.assign([], wo.visits)
          visits[0] = Object.assign({}, visits[0], {
            visitStatus: action.visitStatus,
            checkInGeofence: action.checkInGeofence,
          })
          updatedWorkOrder = { ...wo, visits: visits }
        }
        return updatedWorkOrder
      })
      const newState = {
        ...state,
        myWorkOrders: workOrders,
      }
      return newState
    }
    case SET_CHECK_IN_GEOFENCE: {
      const newState = {
        ...state,
        checkInGeofence: action.checkInGeofence,
      }
      return newState
    }
    case REMOVE_WORK_ORDER: {
      const workOrders = state.myWorkOrders.filter((wo) => {
        return wo.workOrderNumber !== action.workOrderNumber
      })
      const newState = {
        ...state,
        myWorkOrders: workOrders,
      }
      return newState
    }
    case SET_WORK_ORDER_STATUS: {
      const workOrders = state.myWorkOrders.map((wo) => {
        let updatedWorkOrder = wo
        if (wo.workOrderNumber === action.workOrderNumber) {
          updatedWorkOrder = { ...wo, status: action.workOrderStatus }
        }
        return updatedWorkOrder
      })

      const newState = {
        ...state,
        myWorkOrders: workOrders,
      }
      return newState
    }

    default:
      return state
  }
}
