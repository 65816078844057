import { Component } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { CANCEL, DELETE_PROFILE } from '../../globalConstants'
import { getURLSearchParams } from '../../windowManager'
import ConfirmDeleteButton from './ConfirmDeleteButton'

class DeleteProfileDialog extends Component {
  constructor() {
    super()
    this.state = {
      open: false,
    }
  }

  onHashChange = () => {
    const searchParams = getURLSearchParams()
    const open = Boolean(searchParams.get('open'))
    this.setState({ open: open })
  }

  onClose = () => {
    window.history.back()
  }

  componentDidMount = () => {
    window.addEventListener('hashchange', this.onHashChange)
    this.onHashChange()
  }

  componentWillUnmount = () => {
    window.removeEventListener('hashchange', this.onHashChange)
  }

  render = () => {
    return (
      <Dialog
        onClose={this.onClose}
        open={this.state.open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>{DELETE_PROFILE}?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete your profile? Visits associated with
            a deleted profile will not be available for use.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.onClose}>{CANCEL}</Button>
          <ConfirmDeleteButton />
        </DialogActions>
      </Dialog>
    )
  }
}

export default DeleteProfileDialog
