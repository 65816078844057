import { SET_CURRENT_POSITION, SET_MESSAGE } from './actionType'

export function setCurrentPosition(latitude, longitude) {
  return {
    type: SET_CURRENT_POSITION,
    currentPosition: {
      latitude: latitude,
      longitude: longitude,
    },
  }
}

export function setMessage(message) {
  return {
    type: SET_MESSAGE,
    message: message,
  }
}
