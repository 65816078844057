import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Grid, Paper } from '@material-ui/core'
import moment from 'moment'
import { DATE_MISSING, LOCAL_FORMAT, ARRIVE_BY } from '../../globalConstants'
import { Typography } from '@material-ui/core'
import { setSearchOption } from '../Search/actionCreator'

const styles = (theme) => ({
  paper: {
    margin: theme.spacing(0.75, 1),
    padding: theme.spacing(2),
    cursor: 'pointer',
  },
  gridItemValue: {
    textAlign: 'right',
    flexGrow: 1,
    color: theme.palette.primary.alternate,
  },
})

const WorkOrderList = ({ classes, workOrders, history, setSearchOption }) => {
  const arriveBy = workOrders.expectedResponseTime
  const onClickCardActionArea = () => {
    setSearchOption('woNum')
    let url = `/search#?company_id=${workOrders.companyId}&wonum=${workOrders.workOrderNumber}`
    history.push(url)
  }
  return (
    <>
      <Grid item xs={12} sm={6}>
        <Paper className={classes.paper} onClick={onClickCardActionArea}>
          <Grid container>
            <Grid item>
              <Typography>WO#:</Typography>
            </Grid>
            <Grid item className={classes.gridItemValue}>
              {workOrders.workOrderNumber}
            </Grid>
          </Grid>
          <Grid container>
            <Grid item>
              <Typography>Asset Name:</Typography>
            </Grid>
            <Grid item className={classes.gridItemValue}>
              {workOrders.assetName}
            </Grid>
          </Grid>
          <Grid container>
            <Grid item>
              <Typography>{ARRIVE_BY} </Typography>
            </Grid>
            <Grid item className={classes.gridItemValue}>
              {arriveBy
                ? moment(arriveBy).local().format(LOCAL_FORMAT)
                : DATE_MISSING}
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    session: state.authCredentialsReducer.auth.session,
  }
}

const mapDispatchToProps = {
  setSearchOption,
}

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(WorkOrderList)),
)
