import { PHONE_NUMBER } from '../../globalConstants'
import MuiPhoneNumber from 'material-ui-phone-number'
import { connect } from 'react-redux'
import { setProfilePhone } from './actionCreator'
import { setEditProfilePhone } from '../EditProfile/actionCreator'

const US = 'us'

const PhoneNumberInput = ({
  setProfilePhone,
  profilePhone,
  editedProfilePhone,
  setEditProfilePhone,
  createProfile,
  editProfile,
}) => {
  const handlePhoneChange = (value) => {
    createProfile && setProfilePhone(value)
    editProfile && setEditProfilePhone(value)
  }

  return (
    <MuiPhoneNumber
      fullWidth
      disableDropdown
      disableCountryCode
      disableAreaCodes
      label={PHONE_NUMBER}
      defaultCountry={US}
      onlyCountries={[US]}
      value={createProfile ? profilePhone : editedProfilePhone}
      onChange={handlePhoneChange}
      inputProps={{ 'aria-label': PHONE_NUMBER }}
    />
  )
}

const mapStateToProps = (state) => {
  return {
    profilePhone: state.createProfileReducer.profilePhone,
    editedProfilePhone: state.editProfileReducer.editedProfilePhone,
  }
}

const mapDispatchToProps = {
  setProfilePhone,
  setEditProfilePhone,
}

export default connect(mapStateToProps, mapDispatchToProps)(PhoneNumberInput)
