// These dependencies are bundled within praxis-scripts
import { Component } from 'react'
import { connect } from 'react-redux'
import {
  setProfiles,
  setAuthCredentials,
  setCompanyId,
  setCompanyName,
} from '../../common/UserAuth/actionCreator'
import { toggleRender } from '../MatLinearProgress/actionCreator'
import { setMessage, setError } from '../SplashScreen/actionCreator'
import GatedContent from './GatedContent/GatedContent'
import { PROFILES, GETTING_COMPANY } from '../../globalConstants'
import SplashScreen from '../SplashScreen/SplashScreen'
import { fetchData } from '../../Service/Common/HttpService'
import { getCompanyId } from '../../windowManager'
import { getLocalStorage } from '../../windowManager'
import { matchPath } from 'react-router'
import { companyProtectedRoutes } from './GatedContent/GatedContent'
class Layout extends Component {
  constructor() {
    super()
    this.state = {
      routeMatch: true,
    }
  }

  setCompany = async (companyId) => {
    const { setCompanyId, setCompanyName, setMessage, toggleRender, setError } =
      this.props
    try {
      toggleRender(true)
      const data = await fetchData(`companyName?company_id=${companyId}`)
      setCompanyId(companyId)
      setCompanyName(data.company_name)
    } catch (e) {
      setError(true)
      setMessage('Unable to get Company Name')
      toggleRender(false)
    }
  }

  onHashChange = () => {
    const { setCompanyName, setMessage, companyId, setError, error } =
      this.props
    const companyIdParam = getCompanyId()
    if (companyIdParam !== companyId || error) {
      setError(false)
      setCompanyName('')
      setMessage(GETTING_COMPANY)
      this.setCompany(companyIdParam)
    }
  }

  componentDidMount = async () => {
    const { setProfiles, setAuthCredentials } = this.props

    const routeMatch = companyProtectedRoutes.some((route) => {
      return matchPath(window.location.pathname, {
        path: route.path,
      })
    })

    this.setState({
      routeMatch: routeMatch,
    })

    if (routeMatch) {
      // Getting Company information and setting it in Redux
      const companyIdParam = getCompanyId()
      this.setCompany(companyIdParam)

      // Reading Profiles from localStorage and setting them in Redux
      const storage = getLocalStorage()
      if (storage) {
        const currentProfiles = JSON.parse(storage.getItem(PROFILES)) || []
        if (currentProfiles.length) {
          let lastActiveProfile = currentProfiles.find(
            (profile) => profile.active === true,
          )
          if (!lastActiveProfile) {
            lastActiveProfile = currentProfiles[0]
            lastActiveProfile.active = true
            storage.setItem(PROFILES, JSON.stringify(currentProfiles))
          }

          const activeProfile = {
            session: {
              userInfo: {
                name: lastActiveProfile.name,
                phone: lastActiveProfile.phone,
                lanId: lastActiveProfile.id,
              },
            },
          }
          setProfiles(currentProfiles)
          setAuthCredentials(activeProfile)
        }
      }
    }
    window.addEventListener('hashchange', this.onHashChange)
  }

  componentWillUnmount = () => {
    window.removeEventListener('hashchange', this.onHashChange)
  }

  render = () => {
    return this.state.routeMatch && !this.props.companyName ? (
      <SplashScreen />
    ) : (
      <GatedContent />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    companyName: state.authCredentialsReducer.companyName,
    companyId: state.authCredentialsReducer.companyId,
    error: state.splashScreenReducer.error,
  }
}

const mapDispatchToProps = {
  setAuthCredentials,
  setCompanyId,
  setCompanyName,
  setMessage,
  toggleRender,
  setError,
  setProfiles,
}

export default connect(mapStateToProps, mapDispatchToProps)(Layout)
