import { withStyles } from '@material-ui/core/styles'
import WorkOrderDetail from '../../WorkOrder/WorkOrderDetail/WorkOrderDetail'
import WorkOrderPhotos from '../../WorkOrder/WorkOrderPhotos/WorkOrderPhotos'
import Home from '../../Home/Home'
import VisitDetail from '../../VisitDetail/VisitDetail'
import Sidemenu from '../../Sidemenu/Sidemenu'
import Search from '../../Search/Search'
import UpdateETA from '../../WorkOrder/UpdateETA/UpdateETA'
import MatLinearProgress from '../../MatLinearProgress/MatLinearProgress'
import UserFeedback from '../../UserFeedback/UserFeedback'
import { Switch, Route } from 'react-router-dom'
import ConfirmationScreen from '../../ConfirmationScreen/ConfirmationScreen'
import CompanyProfileHeader from '../../CompanyProfileHeader/CompanyProfileHeader'
import CreateProfile from '../../CreateProfile/CreateProfile'
import EditProfile from '../../EditProfile/EditProfile'
import Faq from '../../Faq/Faq'
import ImageUpload from '../../WorkOrder/ImageUpload/ImageUpload'
import OutsideGeofenceScreen from '../../OutsideGeofenceScreen/OutsideGeofenceScreen'

const styles = (theme) => ({
  offset: theme.mixins.toolbar,
})

export const companyProtectedRoutes = [
  { path: '/search', component: Search },
  { path: '/create-profile', component: CreateProfile },
  { path: '/edit-profile', component: EditProfile },
  { path: '/update-eta', component: UpdateETA },
  { path: '/visit-detail', component: VisitDetail },
  { path: '/work-order-detail', component: WorkOrderDetail },
  { path: '/work-order-photos', component: WorkOrderPhotos },
  { path: '/check-out-confirmation', component: ConfirmationScreen },
  { path: '/faq', component: Faq },
  { path: '/image-upload', component: ImageUpload },
  { path: '/outside-geofence-screen', component: OutsideGeofenceScreen },
]

const GatedContent = ({ classes }) => {
  return (
    <>
      {
        // curious why this is here?
        // https://material-ui.com/components/app-bar/#fixed-placement
      }
      <div className={classes.offset} />
      <Sidemenu />
      <MatLinearProgress />
      <UserFeedback />
      <CompanyProfileHeader />
      <Switch>
        <Route exact path={'/'} component={Home} />
        {companyProtectedRoutes.map((companyProtectedRoute, key) => {
          return (
            <Route
              exact
              path={companyProtectedRoute.path}
              component={companyProtectedRoute.component}
              key={key}
            />
          )
        })}

        <Route component={Home} />
        {/* Leaving in as example*/}
        {/* <ProtectedRoute
  exact
  path="/protected"
  allowed={['APP-OAUTH2-GROUPNAME']}
  render={() => (
    <>
      <b>Authorized content</b> only those logged in and authorized
      can see.
    </>
  )}
/> */}
      </Switch>
    </>
  )
}

export default withStyles(styles)(GatedContent)
