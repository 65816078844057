import {
  SET_FOLLOW_UP_REASON,
  SET_FOLLOW_UP_ACTIVE,
  SET_ERROR_FOLLOW_UP_REASON,
} from './actionType'

export function setFollowUpReason(followUpReason) {
  return {
    type: SET_FOLLOW_UP_REASON,
    followUpReason: followUpReason,
  }
}

export function setFollowUpActive(followUpActive) {
  return {
    type: SET_FOLLOW_UP_ACTIVE,
    followUpActive: followUpActive,
  }
}

export function setFollowUpReasonError(showFollowUpReasonErrorValidation) {
  return {
    type: SET_ERROR_FOLLOW_UP_REASON,
    showFollowUpReasonErrorValidation: showFollowUpReasonErrorValidation,
  }
}
