import {
  SET_PROFILE_NAME,
  SET_PROFILE_PHONE,
  SET_PROFILE_SAVED,
} from './actionType'

export function setProfileName(profileName) {
  return {
    type: SET_PROFILE_NAME,
    profileName: profileName,
  }
}

export function setProfilePhone(profilePhone) {
  return {
    type: SET_PROFILE_PHONE,
    profilePhone: profilePhone,
  }
}

export function setProfileSaved(saved) {
  return {
    type: SET_PROFILE_SAVED,
    saved: saved,
  }
}
